import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { startCase } from 'lodash';
import { useGetAwardByIdQuery } from 'reducers/awards';
import { useGetTeamByIdQuery } from 'reducers/teams';
import { useGetDriverByIdQuery } from 'reducers/drivers';
import { useGetEventByIdQuery } from 'reducers/events';
import { useGetSeriesAliasByIdQuery } from 'reducers/series-aliases';
import { useGetSeriesByIdQuery } from 'reducers/series';
import { useGetSessionByIdQuery } from 'reducers/sessions';
import { useGetOrganizationByIdQuery } from 'reducers/organizations';
import { useGetTrackByIdQuery } from 'reducers/tracks';
import { useGetTrackConfigurationByIdQuery } from 'reducers/track-configurations';

const NamedSegment = ({ id, getDataFunc, getDisplayableText = (data: any) => data.name }: { id: string, getDataFunc: Function, getDisplayableText?: Function }) => {
  const { data } = getDataFunc(id);
  if (!data) return id;
  return getDisplayableText(data);
};

const Breadcrumbs = () => {
  const location = useLocation();
  const segments = location.pathname.split('/');
  let curRef = '/';

  segments.shift();
  if (segments[segments.length - 1] === '') segments.pop();
  const len = segments.length;

  return (
    <Breadcrumb className="mt-3">
      {segments.map((segment, idx) => {
        curRef += `${segment}/`;
        const prevIndex = idx - 1;
        const prevSegment = prevIndex >= 0 && segments[prevIndex];

        return (
          <Breadcrumb.Item
            active={(idx === len - 1)}
            linkAs={Link}
            linkProps={{ to: curRef }}
            key={segment}
          >
            {
              (function () {
                // Attempts to fetch the resource only if the segment is a UUID
                if (segment.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/)) {
                  if (prevSegment === 'aliases') return (<NamedSegment id={segment} getDataFunc={useGetSeriesAliasByIdQuery} />);
                  if (prevSegment === 'awards') return (<NamedSegment id={segment} getDataFunc={useGetAwardByIdQuery} />);
                  if (prevSegment === 'teams') return (<NamedSegment id={segment} getDataFunc={useGetTeamByIdQuery} getDisplayableText={({ number }: { number: string }) => number} />);
                  if (prevSegment === 'configurations') return (<NamedSegment id={segment} getDataFunc={useGetTrackConfigurationByIdQuery} getDisplayableText={({ identifier }: { identifier: string }) => identifier} />);
                  if (prevSegment === 'drivers') return (<NamedSegment id={segment} getDataFunc={useGetDriverByIdQuery} getDisplayableText={({ first_name, last_name }: { first_name: string, last_name: string }) => `${first_name} ${last_name}`} />);
                  if (prevSegment === 'events') return (<NamedSegment id={segment} getDataFunc={useGetEventByIdQuery} />);
                  if (prevSegment === 'series') return (<NamedSegment id={segment} getDataFunc={useGetSeriesByIdQuery} getDisplayableText={({ abbreviation }: { abbreviation: string }) => abbreviation} />);
                  if (prevSegment === 'sessions') return (<NamedSegment id={segment} getDataFunc={useGetSessionByIdQuery} />);
                  if (prevSegment === 'organizations') return (<NamedSegment id={segment} getDataFunc={useGetOrganizationByIdQuery} />);
                  if (prevSegment === 'tracks') return (<NamedSegment id={segment} getDataFunc={useGetTrackByIdQuery} getDisplayableText={({ short_name }: { short_name: string }) => short_name} />);
                  if (
                    prevSegment === 'data-sources' ||
                    prevSegment === 'driver-awards' ||
                    prevSegment === 'results'
                  ) return segment;
                }
                return startCase(segment);
              }())
            }
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
