import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { dateFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { format } from 'date-fns';

import { useGetDriversQuery } from 'reducers/drivers';
import PageHeader from 'components/PageHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import BootstrapTable from 'components/BootstrapTable';

export default function DriverList() {
  const navigate = useNavigate();

  const {
    isLoading: driversLoading,
    error: driversError,
    data: driversData,
  } = useGetDriversQuery({});

  if (driversLoading) return <LoadingIndicator />;
  if (driversError) throw driversError;

  const columns = [
    {
      dataField: 'first_name',
      text: 'First Name',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'last_name',
      text: 'Last Name',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'birthdate',
      text: 'Birthdate',
      sort: true,
      filter: dateFilter(),
      formatter: (cell: string | null) => cell && format(new Date(cell), 'M/d/yyyy'),
      headerStyle: {
        width: 225,
      },
    },
    {
      dataField: 'gender',
      text: 'Gender',
      sort: true,
      filter: selectFilter({
        options: [
          { value: 'male', label: 'Male' },
          { value: 'female', label: 'Female' },
        ],
      }),
      formatter: (cell: string) => _.startCase(cell),
      headerStyle: {
        width: 150,
      },
    },
    {
      dataField: 'verified',
      text: 'Verified',
      sort: true,
      filter: selectFilter({
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      }),
      formatter: (cell: boolean) => cell ? 'Yes' : 'No',
      headerStyle: {
        width: 150,
      },
    },
  ];

  const rows: any = _.chain(driversData!.data)
    .map(driver => ({
      _rowLink: `/drivers/${driver.id}`,
      id: driver.id,
      first_name: driver.first_name,
      last_name: driver.last_name,
      email: driver.email,
      birthdate: driver.birthdate,
      gender: driver.gender,
      verified: driver.verified,
    }))
    .orderBy([
      (item) => item.last_name,
      (item) => item.first_name,
    ])
    .value();

  const defaultSorted = [{ dataField: 'lastName', order: 'asc' }];

  return (
    <>
      <PageHeader title="Drivers">
        <Button onClick={() => navigate('/drivers/new')}>Add driver</Button>
      </PageHeader>

      <BootstrapTable cols={columns} rows={rows} defaultSorted={defaultSorted} />
    </>
  );
}
