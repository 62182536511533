import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  formatPayloadFromFormData,
  useInitializeFormFieldValues,
} from 'features/SeriesDataSource/seriesDataSourceFormConfig';
import { useGetSeriesQuery } from 'reducers/series';
import SeriesDataSourceFormFields from '../SeriesDataSourceFormFields';
import { useGetSeriesDataSourceByIdQuery, useUpdateSeriesDataSourceMutation } from 'reducers/series-data-sources';
import { useGetDataSourcesQuery } from 'reducers/data-sources';

export default function SeriesDataSourceEdit() {
  const navigate = useNavigate();
  const { seriesDataSourceId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: seriesLoading,
    error: seriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const {
    isLoading: dataSourcesLoading,
    error: dataSourcesError,
    data: dataSourcesData,
  } = useGetDataSourcesQuery();

  const {
    isLoading: seriesDataSourceLoading,
    error: seriesDataSourceError,
    data: seriesDataSourceData,
  } = useGetSeriesDataSourceByIdQuery(seriesDataSourceId!);

  const [
    updateSeriesDataSource,
    {
      isLoading: updateSeriesDataSourceLoading,
      isSuccess: updateSeriesDataSourceSuccess,
      error: updateSeriesDataSourceError,
    },
  ] = useUpdateSeriesDataSourceMutation();

  useInitializeFormFieldValues(seriesDataSourceData, form);

  React.useEffect(() => {
    if (updateSeriesDataSourceSuccess) navigate(-1);
  }, [updateSeriesDataSourceSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    payload.id = seriesDataSourceId;
    await updateSeriesDataSource(payload);
  };

  if (
    seriesLoading ||
    dataSourcesLoading ||
    seriesDataSourceLoading ||
    updateSeriesDataSourceLoading
  ) return <LoadingIndicator />;

  if (
    seriesError ||
    dataSourcesError ||
    seriesDataSourceError ||
    updateSeriesDataSourceError
  ) throw (seriesError || dataSourcesError || updateSeriesDataSourceError);

  return (
    <>
      <PageHeader title="Edit Series Data Source" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <SeriesDataSourceFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
            dataSources={dataSourcesData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={updateSeriesDataSourceLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
