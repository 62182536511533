import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetTrackByIdQuery, useUpdateTrackMutation } from 'reducers/tracks';
import TrackFormFields from 'features/Track/TrackFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
  formatPayloadFromFormData,
} from 'features/Track/trackFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function TrackEdit() {
  const navigate = useNavigate();
  const { trackId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getTrackLoading,
    error: getTrackError,
    data: trackData,
  } = useGetTrackByIdQuery(trackId!);

  const [
    updateTrack,
    {
      isLoading: updateTrackLoading,
      isSuccess: updateTrackSuccess,
      error: updateTrackError,
    },
  ] = useUpdateTrackMutation();

  useInitializeFormFieldValues(trackData, form);

  // Handle successful form submission
  React.useEffect(() => {
    if (updateTrackSuccess) navigate(-1);
  }, [updateTrackSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    payload.id = trackId;

    await updateTrack(payload);
  };

  if (
    getTrackLoading ||
    updateTrackLoading
  ) return <LoadingIndicator />;

  if (
    getTrackError ||
    updateTrackError
  ) throw (
    getTrackError ||
    updateTrackError
  );

  return (
    <>
      <PageHeader title="Edit Track" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <TrackFormFields
            fieldsConfig={fieldsConfig}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={updateTrackLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
