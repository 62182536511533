import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetAwardsQuery } from 'reducers/awards';
import { useGetDriverAwardByIdQuery, useUpdateDriverAwardMutation } from 'reducers/driver-awards';
import { useGetDriversQuery } from 'reducers/drivers';
import { useGetSessionsQuery } from 'reducers/sessions';
import DriverAwardFormFields from 'features/DriverAward/DriverAwardFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
  formatPayloadFromFormData,
} from 'features/DriverAward/driverAwardFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function DriverAwardEdit() {
  const navigate = useNavigate();
  const { driverAwardId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getDriverAwardLoading,
    error: getDriverAwardError,
    data: driverAward,
  } = useGetDriverAwardByIdQuery(driverAwardId!);

  const {
    isLoading: getAwardsLoading,
    error: getAwardsError,
    data: awardsData,
  } = useGetAwardsQuery({});

  const {
    isLoading: getDriversLoading,
    error: getDriversError,
    data: driversData,
  } = useGetDriversQuery({});

  const {
    isLoading: getSessionsLoading,
    error: getSessionsError,
    data: sessionsData,
  } = useGetSessionsQuery({});

  const [
    updateDriverAward,
    {
      isLoading: updateDriverAwardLoading,
      isSuccess: updateDriverAwardSuccess,
      error: updateDriverAwardError,
    },
  ] = useUpdateDriverAwardMutation();

  useInitializeFormFieldValues(driverAward, form);

  // Handle successful form submission
  React.useEffect(() => {
    if (updateDriverAwardSuccess) navigate(-1);
  }, [updateDriverAwardSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    payload.id = driverAwardId;

    await updateDriverAward(payload);
  };

  if (
    getDriverAwardLoading ||
    getAwardsLoading ||
    getDriversLoading ||
    getSessionsLoading ||
    updateDriverAwardLoading
  ) return <LoadingIndicator />;

  if (
    getDriverAwardError ||
    getAwardsError ||
    getDriversError ||
    getSessionsError ||
    updateDriverAwardError
  ) throw (
    getDriverAwardError ||
    getAwardsError ||
    getDriversError ||
    getSessionsError ||
    updateDriverAwardError
  );

  return (
    <>
      <PageHeader title="Edit Driver Award" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <DriverAwardFormFields
            fieldsConfig={fieldsConfig}
            awards={awardsData!.data}
            drivers={driversData!.data}
            sessions={sessionsData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={updateDriverAwardLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
