import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetAwardsQuery } from 'reducers/awards';
import { useCreateDriverAwardMutation } from 'reducers/driver-awards';
import { useGetDriversQuery } from 'reducers/drivers';
import { useGetSessionsQuery } from 'reducers/sessions';
import DriverAwardFormFields from 'features/DriverAward/DriverAwardFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  formatPayloadFromFormData,
} from 'features/DriverAward/driverAwardFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function DriverAwardNew() {
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getAwardsLoading,
    error: getAwardsError,
    data: awardsData,
  } = useGetAwardsQuery({});

  const {
    isLoading: getDriversLoading,
    error: getDriversError,
    data: driversData,
  } = useGetDriversQuery({});

  const {
    isLoading: getSessionsLoading,
    error: getSessionsError,
    data: sessionsData,
  } = useGetSessionsQuery({});

  const [
    createDriverAward,
    {
      isLoading: createDriverAwardLoading,
      isSuccess: createDriverAwardSuccess,
      error: createDriverAwardError,
    },
  ] = useCreateDriverAwardMutation();

  React.useEffect(() => {
    if (createDriverAwardSuccess) navigate(-1);
  }, [createDriverAwardSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    await createDriverAward(payload);
  };

  if (
    getAwardsLoading ||
    getDriversLoading ||
    getSessionsLoading ||
    createDriverAwardLoading
  ) return <LoadingIndicator />;

  if (
    getAwardsError ||
    getDriversError ||
    getSessionsError ||
    createDriverAwardError
  ) throw (
    getAwardsError ||
    getDriversError ||
    getSessionsError ||
    createDriverAwardError
  );

  return (
    <>
      <PageHeader title="Add Driver Award" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <DriverAwardFormFields
            fieldsConfig={fieldsConfig}
            awards={awardsData!.data}
            drivers={driversData!.data}
            sessions={sessionsData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={createDriverAwardLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
