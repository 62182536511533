import { useEffect } from 'react';

import { SessionResult, SessionResultInput } from 'types';
import msToTime from 'helpers/msToTime';
import timeToMs from 'helpers/timeToMs';
import stringToNumber from 'helpers/stringToNumber';
import numberToString from 'helpers/numberToString';

export const fieldsConfig = {
  session_id: {
    name: 'session_id',
    label: 'Session',
    validation: {
      required: true,
    },
  },
  team_id: {
    name: 'team_id',
    label: 'Team',
    validation: {
      required: true,
    },
  },
  driver_id: {
    name: 'driver_id',
    label: 'Driver',
    validation: {
      required: true,
    },
  },
  last_lap_time: {
    name: 'last_lap_time',
    label: 'Last Lap Time',
    validation: {
      pattern: /\d{2}:\d{2}:\d{2}\.\d{3}/,
    },
    validationMessages: {
      pattern: 'Must be in the format "hh:mm:ss.SSS"',
    },
  },
  overall_best_lap_time: {
    name: 'overall_best_lap_time',
    label: 'Overall Best Lap Time',
    validation: {
      pattern: /\d{2}:\d{2}:\d{2}\.\d{3}/,
    },
    validationMessages: {
      pattern: 'Must be in the format "hh:mm:ss.SSS"',
    },
  },
  laps_led: {
    name: 'laps_led',
    label: 'Laps Led',
    validation: {
      pattern: /^\d+$/,
    },
    validationMessages: {
      pattern: 'Must be a number',
    },
  },
  fastest_lap: {
    name: 'fastest_lap',
    label: 'Fastest Lap',
  },
  position: {
    name: 'position',
    label: 'Finishing Position',
    validation: {
      pattern: /^\d+$/,
    },
    validationMessages: {
      pattern: 'Must be a number',
    },
  },
  last_pitted_lap: {
    name: 'last_pitted_lap',
    label: 'Last Lap Pitted',
  },
  laps_behind_prec: {
    name: 'laps_behind_prec',
    label: 'Laps Behind Car In Front',
    validation: {
      pattern: /^\d+$/,
    },
    validationMessages: {
      pattern: 'Must be a number',
    },
  },
  time_behind_prec: {
    name: 'time_behind_prec',
    label: 'Time Behind Car In Front',
    validation: {
      pattern: /\d{2}:\d{2}:\d{2}\.\d{3}/,
    },
    validationMessages: {
      pattern: 'Must be in the format "hh:mm:ss.SSS"',
    },
  },
  laps_behind_leader: {
    name: 'laps_behind_leader',
    label: 'Laps Behind Leader',
    validation: {
      pattern: /^\d+$/,
    },
    validationMessages: {
      pattern: 'Must be a number',
    },
  },
  points: {
    name: 'points',
    label: 'Points',
    validation: {
      pattern: /^\d+$/,
    },
    validationMessages: {
      pattern: 'Must be a number',
    },
  },
  start_position: {
    name: 'start_position',
    label: 'Start Position',
    validation: {
      pattern: /^\d+$/,
    },
    validationMessages: {
      pattern: 'Must be a number',
    },
  },
  did_not_finish: {
    name: 'did_not_finish',
    label: 'Did Not Finish',
  },
  time_behind_leader: {
    name: 'time_behind_leader',
    label: 'Time Behind Leader',
    validation: {
      pattern: /\d{2}:\d{2}:\d{2}\.\d{3}/,
    },
    validationMessages: {
      pattern: 'Must be in the format "hh:mm:ss.SSS"',
    },
  },
  elapsed_time: {
    name: 'elapsed_time',
    label: 'Elapsed Time',
    validation: {
      pattern: /\d{2}:\d{2}:\d{2}\.\d{3}/,
    },
    validationMessages: {
      pattern: 'Must be in the format "hh:mm:ss.SSS"',
    },
  },
  pit_stop_count: {
    name: 'pit_stop_count',
    label: 'Pit Stop Count',
    validation: {
      pattern: /^\d+$/,
    },
    validationMessages: {
      pattern: 'Must be a number',
    },
  },
  laps_completed: {
    name: 'laps_completed',
    label: 'Laps Completed',
    validation: {
      pattern: /^\d+$/,
    },
    validationMessages: {
      pattern: 'Must be a number',
    },
  },
  fastest_lap_time: {
    name: 'fastest_lap_time',
    label: 'Fastest Lap Time',
    validation: {
      pattern: /\d{2}:\d{2}:\d{2}\.\d{3}/,
    },
    validationMessages: {
      pattern: 'Must be in the format "hh:mm:ss.SSS"',
    },
  },
  overall_rank: {
    name: 'overall_rank',
    label: 'Overall Rank',
    validation: {
      pattern: /^\d+$/,
    },
    validationMessages: {
      pattern: 'Must be a number',
    },
  },
};

export interface FormData {
  session_id: string;
  team_id: string;
  driver_id: string;
  position: string;
  overall_rank: string;
  start_position: string;
  points: string;
  fastest_lap: string;
  fastest_lap_time: string;
  overall_best_lap_time: string;
  last_lap_time: string;
  laps_led: string;
  last_pitted_lap: string;
  laps_behind_prec: string;
  time_behind_prec: string;
  laps_behind_leader: string;
  time_behind_leader: string;
  did_not_finish: string;
  elapsed_time: string;
  pit_stop_count: string;
  laps_completed: string;
};

export const defaultValues = {
  session_id: '',
  team_id: '',
  driver_id: '',
  position: '',
  overall_rank: '',
  start_position: '',
  points: '',
  fastest_lap: '',
  fastest_lap_time: '',
  overall_best_lap_time: '',
  last_lap_time: '',
  laps_led: '',
  last_pitted_lap: '',
  laps_behind_prec: '',
  time_behind_prec: '',
  laps_behind_leader: '',
  time_behind_leader: '',
  did_not_finish: '',
  elapsed_time: '',
  pit_stop_count: '',
  laps_completed: '',
};

export const useInitializeFormFieldValues = (sessionResult: SessionResult | undefined, form: any) => {
  useEffect(() => {
    if (!sessionResult) return;

    form.setValue('session_id', sessionResult.session_id);
    form.setValue('team_id', sessionResult.team_id);
    form.setValue('driver_id', sessionResult.driver_id);
    form.setValue('position', numberToString(sessionResult.position));
    form.setValue('overall_rank', numberToString(sessionResult.overall_rank));
    form.setValue('start_position', numberToString(sessionResult.start_position));
    form.setValue('points', numberToString(sessionResult.points));
    form.setValue('fastest_lap', numberToString(sessionResult.fastest_lap));
    form.setValue('fastest_lap_time', msToTime(sessionResult.fastest_lap_time));
    form.setValue('overall_best_lap_time', msToTime(sessionResult.overall_best_lap_time));
    form.setValue('last_lap_time', msToTime(sessionResult.last_lap_time));
    form.setValue('laps_led', numberToString(sessionResult.laps_led));
    form.setValue('last_pitted_lap', numberToString(sessionResult.last_pitted_lap));
    form.setValue('laps_behind_prec', numberToString(sessionResult.laps_behind_prec));
    form.setValue('time_behind_prec', msToTime(sessionResult.time_behind_prec));
    form.setValue('laps_behind_leader', numberToString(sessionResult.laps_behind_leader));
    form.setValue('time_behind_leader', msToTime(sessionResult.time_behind_leader));
    form.setValue('did_not_finish', String(sessionResult.did_not_finish));
    form.setValue('elapsed_time', msToTime(sessionResult.elapsed_time));
    form.setValue('pit_stop_count', numberToString(sessionResult.pit_stop_count));
    form.setValue('laps_completed', numberToString(sessionResult.laps_completed));
  }, [sessionResult, form]);
};

export const formatPayloadFromFormData = (formData: FormData): SessionResultInput => {
  return {
    session_id: formData.session_id,
    team_id: formData.team_id,
    driver_id: formData.driver_id,
    position: stringToNumber(formData.position),
    overall_rank: stringToNumber(formData.overall_rank),
    start_position: stringToNumber(formData.start_position),
    points: stringToNumber(formData.points),
    fastest_lap: stringToNumber(formData.fastest_lap),
    fastest_lap_time: timeToMs(formData.fastest_lap_time),
    overall_best_lap_time: timeToMs(formData.overall_best_lap_time),
    last_lap_time: timeToMs(formData.last_lap_time),
    laps_led: stringToNumber(formData.laps_led),
    last_pitted_lap: stringToNumber(formData.last_pitted_lap),
    laps_behind_prec: stringToNumber(formData.laps_behind_prec),
    time_behind_prec: timeToMs(formData.time_behind_prec),
    laps_behind_leader: stringToNumber(formData.laps_behind_leader),
    time_behind_leader: timeToMs(formData.time_behind_leader),
    did_not_finish: formData.did_not_finish === 'true',
    elapsed_time: timeToMs(formData.elapsed_time),
    pit_stop_count: stringToNumber(formData.pit_stop_count),
    laps_completed: stringToNumber(formData.laps_completed),
  };
};
