import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';

import { useGetSeriesQuery } from 'reducers/series';
import { useGetTeamsQuery } from 'reducers/teams';
import SectionHeader from 'components/SectionHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import BootstrapTable from 'components/BootstrapTable';
import { useGetOrganizationsQuery } from 'reducers/organizations';

interface Props {
  disabledColumns: string[];
}

function TeamList(props: Props) {
  const navigate = useNavigate();
  const { seriesId } = useParams();

  const {
    isLoading: getSeriesLoading,
    error: getSeriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const {
    isLoading: getOrganizationsLoading,
    error: getOrganizationsError,
    data: organizationsData,
  } = useGetOrganizationsQuery({});

  const {
    isLoading: getTeamsLoading,
    error: getTeamsError,
    data: teamsData,
  } = useGetTeamsQuery({ series_id: seriesId });

  if (
    getSeriesLoading ||
    getTeamsLoading ||
    getOrganizationsLoading
  ) return <LoadingIndicator />;

  if (
    getSeriesError ||
    getTeamsError ||
    getOrganizationsError
  ) throw (
    getSeriesError ||
    getTeamsError ||
    getOrganizationsError
  );

  const columns = [
    {
      dataField: 'series.id',
      text: 'Series',
      sort: true,
      filter: selectFilter({
        options: _.map(seriesData!.data, series => ({
          value: series.id,
          label: series.abbreviation,
        })),
        defaultValue: seriesId,
        disabled: props.disabledColumns.includes('series.id'),
      }),
      formatter: (cell: any, row: any) => row.series.abbreviation,
      headerStyle: {
        width: 300,
      },
    },
    {
      dataField: 'number',
      text: 'Number',
      sort: true,
      sortFunc: (a: string, b: string, order: string) => {
        if (order === 'asc') return Number(b) - Number(a);
        return Number(a) - Number(b);
      },
      filter: textFilter(),
      headerStyle: {
        width: 175,
      },
    },
    {
      dataField: 'organization.name',
      text: 'Organization',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'verified',
      text: 'Verified',
      sort: true,
      filter: selectFilter({
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      }),
      formatter: (cell: boolean) => cell ? 'Yes' : 'No',
      headerStyle: {
        width: 175,
      },
    },
  ];

  const rows: any = _.chain(teamsData!.data)
    .map(team => ({
      _rowLink: `/series/${seriesId}/teams/${team.id}`,
      id: team.id,
      series: _.find(seriesData!.data, { id: team.series_id }),
      number: team.number,
      verified: team.verified,
      organization: _.find(organizationsData!.data, { id: team.organization_id }),
    }))
    .orderBy([
      (item) => item.series,
      (item) => Number(item.number),
    ])
    .value();

  const defaultSorted = [{ dataField: 'series.id', order: 'asc' }];

  return (
    <>
      <SectionHeader title="Teams">
        <Button size="sm" onClick={() => navigate(`/series/${seriesId}/teams/new`)}>Add team</Button>
      </SectionHeader>

      <BootstrapTable cols={columns} rows={rows} defaultSorted={defaultSorted} />
    </>
  );
}


TeamList.defaultProps = {
  disabledColumns: [],
};

export default TeamList;
