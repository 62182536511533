import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query/react'

import { useGetTeamsQuery } from 'reducers/teams';
import { useGetDriversQuery } from 'reducers/drivers';
import { useGetSessionResultByIdQuery, useDeleteSessionResultMutation } from 'reducers/session-results';
import { useGetSessionByIdQuery, useGetSessionsQuery } from 'reducers/sessions';
import SessionResultFormFields from 'features/SessionResult/SessionResultFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
} from 'features/SessionResult/sessionResultFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import DeleteConfirmationButton from 'components/DeleteConfirmationButton';

export default function SessionResultDetail() {
  const navigate = useNavigate();
  const { seriesId, eventId, sessionId, sessionResultId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getSessionResultLoading,
    error: getSessionResultError,
    data: sessionResultData,
  } = useGetSessionResultByIdQuery(sessionResultId!);

  const {
    isLoading: getSessionLoading,
    error: getSessionError,
    data: sessionData,
  } = useGetSessionByIdQuery(sessionResultData?.session_id ?? skipToken);

  const {
    isLoading: getSessionsLoading,
    error: getSessionsError,
    data: sessionsData,
  } = useGetSessionsQuery(sessionData?.event_id ? { event_id: sessionData?.event_id } : skipToken);

  const {
    isLoading: getTeamsLoading,
    error: getTeamsError,
    data: teamsData,
  } = useGetTeamsQuery({ series_id: seriesId });

  const {
    isLoading: getDriversLoading,
    error: getDriversError,
    data: driversData,
  } = useGetDriversQuery({});

  const [
    deleteSessionResult,
    {
      isLoading: deleteSessionResultLoading,
      error: deleteSessionResultError,
      isSuccess: deleteSessionResultSuccess,
    },
  ] = useDeleteSessionResultMutation();

  useInitializeFormFieldValues(sessionResultData, form);

  React.useEffect(() => {
    if (deleteSessionResultSuccess) navigate(-1);
  }, [deleteSessionResultSuccess, navigate]);

  if (getSessionResultLoading ||
    getSessionLoading ||
    getSessionsLoading ||
    getTeamsLoading ||
    getDriversLoading ||
    deleteSessionResultLoading
  ) return <LoadingIndicator />;

  if (getSessionResultError ||
    getSessionError ||
    getSessionsError ||
    getTeamsError ||
    getDriversError ||
    deleteSessionResultError
  ) throw (
    getSessionResultError ||
    getSessionError ||
    getSessionsError ||
    getTeamsError ||
    getDriversError ||
    deleteSessionResultError
  );

  return (
    <>
      <PageHeader title={`${sessionData!.name} Result`}>
        <Button onClick={() => navigate(`/series/${seriesId}/events/${eventId}/sessions/${sessionId}/results/${sessionResultId}/edit`)}>Edit session result</Button>
        <DeleteConfirmationButton buttonText="Delete session result" action={() => deleteSessionResult(sessionResultId!)} />
      </PageHeader>

      <FormProvider {...form}>
        <Form>
          <SessionResultFormFields
            fieldsConfig={fieldsConfig}
            sessions={sessionsData!.data}
            teams={teamsData!.data}
            drivers={driversData!.data}
            disabled
          />
        </Form>
      </FormProvider>
    </>
  );
}
