import { useEffect } from 'react';

import { Series, SeriesInput } from 'types';

export const fieldsConfig = {
  letter: {
    name: 'letter',
    label: 'Letter',
    validation: {
      required: true,
      pattern: /^[A-Z]$/,
    },
    validationMessages: {
      pattern: 'Only one letter is allowed',
    }
  },
  abbreviation: {
    name: 'abbreviation',
    label: 'Abbreviation',
    validation: {
      required: true,
      pattern: /^[a-zA-Z]+$/,
    },
    validationMessages: {
      pattern: 'Only letters are allowed',
    }
  },
};

export interface FormData {
  letter: string;
  abbreviation: string;
};

export const defaultValues = {
  letter: '',
  abbreviation: '',
};

export const useInitializeFormFieldValues = (series: Series | undefined, form: any) => {
  useEffect(() => {
    if (!series) return;

    form.setValue('letter', series.letter);
    form.setValue('abbreviation', series.abbreviation);
  }, [series, form]);
};

export const formatPayloadFromFormData = (formData: FormData): SeriesInput => {
  return {
    letter: formData.letter,
    abbreviation: formData.abbreviation,
  };
};
