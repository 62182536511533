import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Form, Tab } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetTrackByIdQuery, useDeleteTrackMutation } from 'reducers/tracks';
import TrackFormFields from 'features/Track/TrackFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
} from 'features/Track/trackFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import DeleteConfirmationButton from 'components/DeleteConfirmationButton';
import TrackConfigurationList from 'features/TrackConfiguration/TrackConfigurationList';
import PersistentTabs from 'components/PersistentTabs';

export default function TrackDetail() {
  const navigate = useNavigate();
  const { trackId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getTrackLoading,
    error: getTrackError,
    data: trackData,
  } = useGetTrackByIdQuery(trackId!);

  const [
    deleteTrack,
    {
      isLoading: deleteTrackLoading,
      error: deleteTrackError,
      isSuccess: deleteTrackSuccess,
    },
  ] = useDeleteTrackMutation();

  useInitializeFormFieldValues(trackData, form);

  React.useEffect(() => {
    if (deleteTrackSuccess) navigate(-1);
  }, [deleteTrackSuccess, navigate]);

  if (
    getTrackLoading ||
    deleteTrackLoading
  ) return <LoadingIndicator />;

  if (
    getTrackError ||
    deleteTrackError
  ) throw (
    getTrackError ||
    deleteTrackError
  );

  return (
    <>
      <PageHeader title={trackData!.short_name}>
        <Button onClick={() => navigate(`/tracks/${trackId}/edit`)}>Edit track</Button>
        <DeleteConfirmationButton buttonText="Delete track" action={() => deleteTrack(trackId!)} />
      </PageHeader>

      <PersistentTabs
        defaultActiveKey="details"
        className="mb-3"
      >
        <Tab eventKey="details" title="Details">
          <FormProvider {...form}>
            <Form>
              <TrackFormFields
                fieldsConfig={fieldsConfig}
                disabled
              />
            </Form>
          </FormProvider>
        </Tab>

        <Tab eventKey="configurations" title="Configurations">
          <TrackConfigurationList />
        </Tab>
      </PersistentTabs>
    </>
  );
}
