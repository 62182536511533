import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import {selectFilter, textFilter } from 'react-bootstrap-table2-filter';

import { useGetOrganizationsQuery } from 'reducers/organizations';
import PageHeader from 'components/PageHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import BootstrapTable from 'components/BootstrapTable';

export default function OrganizationList() {
  const navigate = useNavigate();

  const {
    isLoading: organizationsLoading,
    error: organizationsError,
    data: organizationsData,
  } = useGetOrganizationsQuery({});

  if (organizationsLoading) return <LoadingIndicator />;
  if (organizationsError) throw organizationsError;

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'verified',
      text: 'Verified',
      sort: true,
      filter: selectFilter({
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      }),
      formatter: (cell: boolean) => cell ? 'Yes' : 'No',
      headerStyle: {
        width: 175,
      },
    },
  ];

  const rows: any = _.chain(organizationsData!.data)
    .map(organization => ({
      _rowLink: `/organizations/${organization.id}`,
      id: organization.id,
      name: organization.name,
      verified: organization.verified,
    }))
    .orderBy([
      (item) => item.name,
    ])
    .value();

  const defaultSorted = [{ dataField: 'lastName', order: 'asc' }];

  return (
    <>
      <PageHeader title="Organizations">
        <Button onClick={() => navigate('/organizations/new')}>Add organization</Button>
      </PageHeader>

      <BootstrapTable cols={columns} rows={rows} defaultSorted={defaultSorted} />
    </>
  );
}
