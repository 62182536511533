import React from 'react';
import { useNavigate } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { SIZE_PER_PAGE } from 'config';

interface Props {
  cols: any[];
  rows: any[];
  defaultSorted?: any;
}

export default function BootstrapTableWrapper(props: Props) {
  const navigate = useNavigate();

  const columns = props.cols.map((col, i) => ({
    key: `col-${i}`,
    ...col,
    style: {
      ...col.style,
      cursor: 'pointer',
    },
  }));

  const rows = props.rows.map((row, i) => ({
    key: `row-${i}`,
    ...row,
  }));

  return (
    <BootstrapTable
      bootstrap4
      keyField="key"
      hover
      striped
      columns={columns}
      data={rows}
      defaultSorted={props.defaultSorted}
      rowEvents={{ onClick: (e: any, row: any) => (row._rowLink) && navigate(row._rowLink) }}
      filter={filterFactory()}
      pagination={paginationFactory({ sizePerPage: SIZE_PER_PAGE })}
    />
  );
}
