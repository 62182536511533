import { useEffect } from 'react';

import { SeriesAlias, SeriesAliasInput } from 'types';
import stringToNumber from 'helpers/stringToNumber';
import numberToString from 'helpers/numberToString';

export const fieldsConfig = {
  series_id: {
    name: 'series_id',
    label: 'Series',
    validation: {
      required: true,
    },
  },
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  year: {
    name: 'year',
    label: 'Year',
    validation: {
      required: true,
      pattern: /^[0-9]{4}$/,
    },
    validationMessages: {
      pattern: 'Year must be a 4 digit number',
    },
  },
};

export interface FormData {
  series_id: string;
  name: string;
  year: string;
};

export const defaultValues = {
  series_id: '',
  name: '',
  year: '',
};

export const useInitializeFormFieldValues = (seriesAlias: SeriesAlias | undefined, form: any) => {
  useEffect(() => {
    if (!seriesAlias) return;

    form.setValue('series_id', seriesAlias.series_id);
    form.setValue('name', seriesAlias.name);
    form.setValue('year', numberToString(seriesAlias.year));
  }, [seriesAlias, form]);
};

export const formatPayloadFromFormData = (formData: FormData): SeriesAliasInput => {
  return {
    series_id: formData.series_id,
    name: formData.name,
    year: stringToNumber(formData.year),
  };
};
