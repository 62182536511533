import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetEventsQuery } from 'reducers/events';
import { useCreateSessionMutation } from 'reducers/sessions';
import SessionFormFields from 'features/Session/SessionFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  formatPayloadFromFormData,
} from 'features/Session/sessionFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function SessionNew() {
  const { seriesId, eventId } = useParams();

  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: eventsLoading,
    error: eventsError,
    data: eventsData,
  } = useGetEventsQuery({ series_id: seriesId });

  const [
    createSession,
    {
      isLoading: createSessionLoading,
      isSuccess: createSessionSuccess,
      error: createSessionError,
    },
  ] = useCreateSessionMutation();

  React.useEffect(() => {
    form.setValue('event_id', eventId!);
  }, [eventId, form]);

  React.useEffect(() => {
    if (createSessionSuccess) navigate(-1);
  }, [createSessionSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    payload.event_id = eventId!;

    await createSession(payload);
  };

  if (
    eventsLoading ||
    createSessionLoading
  ) return <LoadingIndicator />;

  if (
    eventsError ||
    createSessionError
  ) throw (
    eventsError ||
    createSessionError
  );

  return (
    <>
      <PageHeader title="Add Session" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <SessionFormFields
            fieldsConfig={fieldsConfig}
            events={eventsData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={createSessionLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
