import React from 'react';
import { Tabs, TabsProps } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

export default function PersistentTabs(props: TabsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const activeTab = React.useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('tab');
  }, [location]);

  return (
    <Tabs
      {...props}
      activeKey={activeTab ?? props.defaultActiveKey}
      onSelect={key => navigate(`?tab=${key}`)}
    >
      {props.children}
    </Tabs>
  );
}
