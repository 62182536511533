import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useCreateTrackMutation } from 'reducers/tracks';
import TrackFormFields from 'features/Track/TrackFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  formatPayloadFromFormData,
} from 'features/Track/trackFormConfig';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function TrackNew() {
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });

  const [
    createTrack,
    {
      isLoading: createTrackLoading,
      isSuccess: createTrackSuccess,
    },
  ] = useCreateTrackMutation();

  React.useEffect(() => {
    if (createTrackSuccess) navigate(-1);
  }, [createTrackSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    await createTrack(payload);
  };

  return (
    <>
      <PageHeader title="Add Track" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <TrackFormFields
            fieldsConfig={fieldsConfig}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={createTrackLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
