import React from 'react';
import { Col, Row } from 'react-bootstrap';

import ImageUploadField from 'components/FormFields/ImageUploadField';
import TextField from 'components/FormFields/TextField';
import SelectField from 'components/FormFields/SelectField';
import { Series } from 'types';
import { fieldsConfig } from '../organizationFormConfig';

interface Props {
  fieldsConfig: typeof fieldsConfig;
  series: Series[];
  disabled: boolean;
}

function OrganizationFormFields(props: Props) {
  return (
    <>
      <Row>
        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.name}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={6}>
          <ImageUploadField
            config={props.fieldsConfig.logo_url}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={2}>
          <SelectField
            config={props.fieldsConfig.verified}
            options={[
              { value: 'true', label: 'Yes' },
              { value: 'false', label: 'No' },
            ]}
            disabled={props.disabled}
          />
        </Col>
      </Row>
    </>
  );
};

OrganizationFormFields.defaultProps = {
  disabled: false,
};

export default OrganizationFormFields;
