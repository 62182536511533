import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

import type {
  Track,
  TrackInput,
  TrackSearchQuery,
  TrackSearchResult,
} from 'types';
import { BASE_URL } from 'config';

export const tracksApi = createApi({
  reducerPath: 'tracksApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Track'],
  endpoints: (build) => ({
    getTracks: build.query<TrackSearchResult, TrackSearchQuery>({
      query: (queryParams) => `tracks?${qs.stringify(queryParams)}`,
      providesTags: (result) =>
        result!.data
          ? [
            ...result!.data.map(({ id }) => ({ type: 'Track' as const, id })),
            { type: 'Track', id: 'LIST' },
          ]
          : [{ type: 'Track', id: 'LIST' }],
    }),
    getTrackById: build.query<Track, string>({
      query: (id) => `tracks/${id}`,
      providesTags: (result, error, id) => [{ type: 'Track', id }],
    }),
    createTrack: build.mutation<Track, TrackInput>({
      query: (body) => {
        return {
          url: 'tracks',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'Track', id: 'LIST' }],
    }),
    updateTrack: build.mutation<Track, TrackInput>({
      query: (body) => ({
        url: `tracks/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Track', id }],
    }),
    deleteTrack: build.mutation<void, string>({
      query: (id) => ({
        url: `tracks/${id}`,
        method: 'DELETE',
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Track', id }],
    }),
  }),
});

export const {
  useGetTracksQuery,
  useGetTrackByIdQuery,
  useCreateTrackMutation,
  useUpdateTrackMutation,
  useDeleteTrackMutation,
} = tracksApi;
