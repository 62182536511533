import React from 'react';
import { Col, Row } from 'react-bootstrap';

import ImageUploadField from 'components/FormFields/ImageUploadField';
import TextField from 'components/FormFields/TextField';
import SelectField from 'components/FormFields/SelectField';
import { Organization, Series } from 'types';
import { fieldsConfig } from '../teamFormConfig';

interface Props {
  fieldsConfig: typeof fieldsConfig;
  series: Series[];
  disabled: boolean;
  organizations: Organization[];
}

function TeamFormFields(props: Props) {
  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <SelectField
            config={props.fieldsConfig.series_id}
            options={props.series.map(s => ({ label: s.abbreviation, value: s.id }))}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.number}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={2}>
          <SelectField
            config={props.fieldsConfig.verified}
            options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]}
            disabled={props.disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <SelectField
            config={props.fieldsConfig.organization_id}
            options={props.organizations.map(s => ({ label: s.name, value: s.id }))}
            disabled={props.disabled}
          />
        </Col>
      </Row>
      <Row>
        <ImageUploadField
          config={props.fieldsConfig.livery_url}
          disabled={props.disabled}
        />
      </Row>
    </>
  );
};

TeamFormFields.defaultProps = {
  disabled: false,
};

export default TeamFormFields;
