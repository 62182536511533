import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';

import { useGetDataSourcesQuery } from 'reducers/data-sources';
import PageHeader from 'components/PageHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import BootstrapTable from 'components/BootstrapTable';

interface Props {
  disabledColumns: string[];
}

function DataSourceList(props: Props) {
  const navigate = useNavigate();

  const {
    isLoading: dataSourcesLoading,
    error: dataSourcesError,
    data: dataSourcesData,
  } = useGetDataSourcesQuery();

  if (dataSourcesLoading) return <LoadingIndicator />;

  if (dataSourcesError) throw dataSourcesError;

  const columns = [
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'source',
      text: 'Source',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'display_name',
      text: 'Display Name',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'is_permanent',
      text: 'Permanent',
      sort: true,
      filter: selectFilter({
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ],
      }),
      formatter: (isPermanent: boolean) => (isPermanent) ? 'Yes' : 'No',
      headerStyle: {
        width: 150,
      },
    }
  ];

  const rows: any = _.chain(dataSourcesData!.data)
    .map(dataSource => ({
      _rowLink: `/data-sources/${dataSource.id}`,
      id: dataSource.id,
      display_name: dataSource.display_name,
      source: dataSource.source,
      type: dataSource.type,
      is_permanent: dataSource.is_permanent,
    }))
    .orderBy([
      (item) => item.type,
      (item) => item.display_name,
    ])
    .value();

  const defaultSorted = [{ dataField: 'type', order: 'asc' }];

  return (
    <>
      <PageHeader title="Data Sources">
        <Button onClick={() => navigate('/data-sources/new')}>Create Data Source</Button>
      </PageHeader>

      <BootstrapTable
        cols={columns}
        rows={rows}
        defaultSorted={defaultSorted}
      />
    </>
  );
}

DataSourceList.defaultProps = {
  disabledColumns: [],
};

export default DataSourceList;
