import { useEffect } from 'react';

import { DriverAward, DriverAwardInput } from 'types';

export const fieldsConfig = {
  session_id: {
    name: 'session_id',
    label: 'Session',
    validation: {
      required: true,
    },
  },
  award_id: {
    name: 'award_id',
    label: 'Award',
    validation: {
      required: true,
    },
  },
  driver_id: {
    name: 'driver_id',
    label: 'Driver',
    validation: {
      required: true,
    },
  },
};

export interface FormData {
  session_id: string;
  award_id: string;
  driver_id: string;
};

export const defaultValues = {
  session_id: '',
  award_id: '',
  driver_id: '',
};

export const useInitializeFormFieldValues = (driverAward: DriverAward | undefined, form: any) => {
  useEffect(() => {
    if (!driverAward) return;

    form.setValue('session_id', driverAward.session_id);
    form.setValue('award_id', driverAward.award_id);
    form.setValue('driver_id', driverAward.driver_id);
  }, [driverAward, form]);
};

export const formatPayloadFromFormData = (formData: FormData): DriverAwardInput => {
  return {
    session_id: formData.session_id,
    award_id: formData.award_id,
    driver_id: formData.driver_id,
  };
};
