import { textFilter } from 'react-bootstrap-table2-filter';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';

import BootstrapTable from 'components/BootstrapTable';
import { useGetEventByIdQuery } from 'reducers/events';
import LoadingIndicator from 'components/LoadingIndicator';
import { Button, Form } from 'react-bootstrap';
import SectionHeader from 'components/SectionHeader';

export default function EventDataSourcesList() {
  const navigate = useNavigate();
  const { eventId, seriesId } = useParams();

  const {
    isLoading: getEventLoading,
    error: getEventError,
    data: event,
  } = useGetEventByIdQuery(eventId!);

  if (getEventLoading) return <LoadingIndicator />;

  if (getEventError) throw getEventError;

  const cols = [
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'source',
      text: 'Source',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'display_name',
      text: 'Display Name',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'is_primary',
      text: 'Primary',
      filter: textFilter(),
      sort: true,
    },
  ];

  const rows = _.chain(event!.data_sources)
    .map(d => {
      return {
        _rowLink: `/series/${seriesId}/events/${event!.id}/data-sources/${d.id}`,
        ...d,
        is_primary: <Form.Check checked={d.is_primary} disabled />,
      };
    })
    .orderBy([item => item.source])
    .value();

const defaultSorted = [{ dataField: 'source', order: 'asc' }];

  return (
    <>
      <SectionHeader title="Event Data Sources">
        <Button size="sm" onClick={() => navigate(`/series/${seriesId}/events/${event!.id}/data-sources/new`)}>Add event data source</Button>
      </SectionHeader>

      <BootstrapTable
        cols={cols}
        rows={rows}
        defaultSorted={defaultSorted}
      />
    </>
  );
}
