import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Form, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetAwardsQuery } from 'reducers/awards';
import { useGetDriversQuery } from 'reducers/drivers';
import { useGetDriverAwardByIdQuery, useDeleteDriverAwardMutation } from 'reducers/driver-awards';
import { useGetSessionsQuery } from 'reducers/sessions';
import DriverAwardFormFields from 'features/DriverAward/DriverAwardFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
} from 'features/DriverAward/driverAwardFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import DeleteConfirmationButton from 'components/DeleteConfirmationButton';

export default function DriverAwardDetail() {
  const navigate = useNavigate();
  const { driverAwardId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getDriverAwardLoading,
    error: getDriverAwardError,
    data: driverAwardData,
  } = useGetDriverAwardByIdQuery(driverAwardId!);

  const {
    isLoading: getAwardsLoading,
    error: getAwardsError,
    data: awardsData,
  } = useGetAwardsQuery({});

  const {
    isLoading: getDriversLoading,
    error: getDriversError,
    data: driversData,
  } = useGetDriversQuery({});

  const {
    isLoading: getSessionsLoading,
    error: getSessionsError,
    data: sessionsData,
  } = useGetSessionsQuery({});

  const [
    deleteDriverAward,
    {
      isLoading: deleteDriverAwardLoading,
      error: deleteDriverAwardError,
      isSuccess: deleteDriverAwardSuccess,
    },
  ] = useDeleteDriverAwardMutation();

  useInitializeFormFieldValues(driverAwardData, form);

  React.useEffect(() => {
    if (deleteDriverAwardSuccess) navigate(-1);
  }, [deleteDriverAwardSuccess, navigate]);

  if (
    getDriverAwardLoading ||
    getAwardsLoading ||
    getDriversLoading ||
    getSessionsLoading ||
    deleteDriverAwardLoading
  ) return <LoadingIndicator />;

  if (
    getDriverAwardError ||
    getAwardsError ||
    getDriversError ||
    getSessionsError ||
    deleteDriverAwardError
  ) throw (
    getDriverAwardError ||
    getAwardsError ||
    getDriversError ||
    getSessionsError ||
    deleteDriverAwardError
  )

  return (
    <>
      <PageHeader title="View Driver Award">
        <Button onClick={() => navigate(`/driver-awards/${driverAwardId}/edit`)}>Edit driver award</Button>
        <DeleteConfirmationButton buttonText="Delete driver award" action={() => deleteDriverAward(driverAwardId!)} />
      </PageHeader>

      <FormProvider {...form}>
        <Form>
          <DriverAwardFormFields
            fieldsConfig={fieldsConfig}
            awards={awardsData!.data}
            drivers={driversData!.data}
            sessions={sessionsData!.data}
            disabled
          />
        </Form>
      </FormProvider>
    </>
  );
}
