import { useEffect } from 'react';

import { EventDataSource, EventDataSourceInput } from 'types';

export const fieldsConfig = {
  id: {
    name: 'id',
    label: 'Data Source',
    validation: {
      required: true,
    },
  },
  is_primary: {
    name: 'is_primary',
    label: 'Primary',
    validation: {
      required: true,
    },
  },
};

export interface FormData {
  id: string;
  data_source_id: string;
  is_primary: boolean;
};

export const defaultValues = {
  id: '',
  is_primary: false,
};

export const useInitializeFormFieldValues = (eventDataSource: EventDataSource | undefined, form: any) => {
  useEffect(() => {
    if (!eventDataSource) return;

    form.setValue('id', eventDataSource.id);
    form.setValue('is_primary', eventDataSource.is_primary);
  }, [eventDataSource, form]);
};

export const formatPayloadFromFormData = (formData: FormData): EventDataSourceInput => {
  return {
    id: formData.id,
    is_primary: formData.is_primary,
  };
};
