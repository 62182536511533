import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { textFilter } from 'react-bootstrap-table2-filter';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import _ from 'lodash';

import { useGetSessionStatsBySessionIdQuery } from 'reducers/session-results';
import LoadingIndicator from 'components/LoadingIndicator';
import BootstrapTable from 'components/BootstrapTable';
import msToTime from 'helpers/msToTime';
import SectionHeader from 'components/SectionHeader';

export default function SessionResultList() {
  const navigate = useNavigate();
  const { seriesId, eventId, sessionId } = useParams();

  const {
    isLoading: getSessionStatsLoading,
    error: getSessionStatsError,
    data: sessionStats,
  } = useGetSessionStatsBySessionIdQuery(sessionId!);

  if (getSessionStatsLoading) return <LoadingIndicator />;

  if (getSessionStatsError) throw getSessionStatsError;

  const popover = (type: string, recordId: string) => (
    <Popover>
      <Popover.Header as="h3">Resolve {_.startCase(type)} Discrepancy</Popover.Header>
      <Popover.Body>
        <Button
          size="sm"
          onClick={async e => {
            e.stopPropagation();
            navigate(`/${type}s/${recordId}/edit`);
          }}
        >
          Verify record
        </Button>
        <hr />
        <Button
          size="sm"
          onClick={async e => {
            e.stopPropagation();
            navigate(`/${type}s/${recordId}/merge`);
          }}
        >
          Merge record
        </Button>
      </Popover.Body>
    </Popover>
  );

  const columns = [
    {
      dataField: 'position',
      text: 'Pos',
      sort: true,
      filter: textFilter(),
      headerStyle: {
        width: 75,
      },
    },
    {
      dataField: 'team_number',
      text: 'Team',
      filter: textFilter(),
      formatter: (cell: string, record: any) => {
        const showDot = !record.teamVerified;

        const dot = (
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="right"
            overlay={popover('team', record.teamId)}
          >
            <FontAwesomeIcon icon={faCircleExclamation} onClick={(e) => e.stopPropagation()} />
          </OverlayTrigger>
        )

        return (
          <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {cell} {showDot && dot}
          </span>
        );
      },
      headerStyle: {
        width: 75,
      },
    },
    {
      dataField: 'driver_first_name',
      text: 'First Name',
      sort: true,
      filter: textFilter(),
      formatter: (cell: string, record: any) => {
        const showDot = !record.driverVerified;

        const dot = (
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="right"
            overlay={popover('driver', record.driverId)}
          >
            <FontAwesomeIcon icon={faCircleExclamation} onClick={(e) => e.stopPropagation()} />
          </OverlayTrigger>
        )

        return (
          <span className="d-flex justify-content-between align-items-center">
            {cell} {showDot && dot}
          </span>
        );
      },
    },
    {
      dataField: 'driver_last_name',
      text: 'Last Name',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'fastest_lap_time',
      text: 'Fastest Lap Time',
      sort: true,
      filter: textFilter(),
      formatter: (cell: number) => msToTime(cell),
      headerStyle: {
        width: 125,
      },
    },
    {
      dataField: 'laps_completed',
      text: 'Laps Completed',
      sort: true,
      filter: textFilter(),
      headerStyle: {
        width: 125,
      },
    },
    {
      dataField: 'time_behind_leader',
      text: 'Time Behind Leader',
      sort: true,
      filter: textFilter(),
      formatter: (cell: number) => msToTime(cell),
      headerStyle: {
        width: 125,
      },
    },
  ];

  const rows = sessionStats!.map((sessionStat) => ({
    _rowLink: `/series/${seriesId}/events/${eventId}/sessions/${sessionId}/results/${sessionStat.id}`,
    ...sessionStat,
  }));

  const defaultSorted = [{ dataField: 'position', order: 'asc' }];

  return (
    <>
      <SectionHeader title="Results">
        <Button size="sm" onClick={() => navigate(`/series/${seriesId}/events/${eventId}/sessions/${sessionId}/results/new`)}>Add result</Button>
      </SectionHeader>

      <BootstrapTable
        cols={columns}
        rows={rows}
        defaultSorted={defaultSorted}
      />
    </>
  );
};
