import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Form, Tab } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetSeriesByIdQuery, useDeleteSeriesMutation } from 'reducers/series';
import { useGetSeriesQuery } from 'reducers/series';
import SeriesFormFields from 'features/Series/SeriesFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
} from 'features/Series/seriesFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import DeleteConfirmationButton from 'components/DeleteConfirmationButton';
import AwardList from 'features/Award/AwardList';
import TeamList from 'features/Team/TeamList';
import EventList from 'features/Event/EventList';
import SeriesAliasList from 'features/SeriesAlias/SeriesAliasList';
import SeriesDataSourceList from 'features/SeriesDataSource/SeriesDataSourceList';
import PersistentTabs from 'components/PersistentTabs';

export default function SeriesDetail() {
  const navigate = useNavigate();
  const { seriesId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getSerieLoading,
    error: getSerieError,
    data: serieData,
  } = useGetSeriesByIdQuery(seriesId!);

  const {
    isLoading: getSeriesLoading,
    error: getSeriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const [
    deleteSeries,
    {
      isLoading: deleteSeriesLoading,
      error: deleteSeriesError,
      isSuccess: deleteSeriesSuccess,
    },
  ] = useDeleteSeriesMutation();

  useInitializeFormFieldValues(serieData, form);

  React.useEffect(() => {
    if (deleteSeriesSuccess) navigate(-1);
  }, [deleteSeriesSuccess, navigate]);

  if (
    getSerieLoading ||
    getSeriesLoading ||
    deleteSeriesLoading
  ) return <LoadingIndicator />;

  if (
    getSerieError ||
    getSeriesError ||
    deleteSeriesError
  ) throw (
    getSerieError ||
    getSeriesError ||
    deleteSeriesError
  );

  return (
    <>
      <PageHeader title={serieData!.abbreviation}>
        <Button onClick={() => navigate(`/series/${seriesId}/edit`)}>Edit series</Button>
        <DeleteConfirmationButton buttonText="Delete series" action={() => deleteSeries(seriesId!)} />
      </PageHeader>

      <PersistentTabs
        defaultActiveKey="details"
        className="mb-3"
      >
        <Tab eventKey="details" title="Details">
          <FormProvider {...form}>
            <Form>
              <SeriesFormFields
                fieldsConfig={fieldsConfig}
                series={seriesData!.data}
                disabled
              />
            </Form>
          </FormProvider>
        </Tab>

        <Tab eventKey="aliases" title="Aliases">
          <SeriesAliasList />
        </Tab>

        <Tab eventKey="awards" title="Awards">
          <AwardList disabledColumns={['series.id']} />
        </Tab>

        <Tab eventKey="teams" title="Teams">
          <TeamList disabledColumns={['series.id']} />
        </Tab>

        <Tab eventKey="events" title="Events">
          <EventList disabledColumns={['series.id']} />
        </Tab>

        <Tab eventKey="dataSources" title="Data Sources">
          <SeriesDataSourceList showSeriesColumn={false} />
        </Tab>
      </PersistentTabs>
    </>
  );
}
