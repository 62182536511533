import { useEffect } from 'react';

import { TrackConfiguration, TrackConfigurationInput } from 'types';
import stringToNumber from 'helpers/stringToNumber';
import numberToString from 'helpers/numberToString';

export const fieldsConfig = {
  track_id: {
    name: 'track_id',
    label: 'Track',
    validation: {
      required: true,
    },
  },
  identifier: {
    name: 'identifier',
    label: 'Identifier',
    validation: {
      required: true,
    },
  },
  turns: {
    name: 'turns',
    label: 'Turns',
    validation: {
      required: true,
      pattern: /^[0-9]+$/,
    },
    validationMessages: {
      pattern: 'Must be a number',
    },
  },
  length: {
    name: 'length',
    label: 'Length',
    validation: {
      required: true,
    },
  },
  svg_url: {
    name: 'svg_url',
    label: 'SVG URL',
  },
  satellite_url: {
    name: 'satellite_url',
    label: 'Satellite URL',
  },
  track_type: {
    name: 'track_type',
    label: 'Track Type',
    validation: {
      required: true,
      pattern: /^(oval|road_course|street)$/,
    },
  },
  pit_road_surface_type: {
    name: 'pit_road_surface_type',
    label: 'Pit Road Surface',
    validation: {
      required: true,
      pattern: /^(asphalt|concrete|dirt)$/,
    },
  },
  track_surface_type: {
    name: 'track_surface_type',
    label: 'Track Surface',
    validation: {
      required: true,
      pattern: /^(asphalt|concrete|dirt)$/,
    },
  },
};

export interface FormData {
  track_id: string;
  identifier: string;
  turns: string;
  length: string;
  svg_url: string;
  satellite_url: string;
  track_type: string;
  pit_road_surface_type: string;
  track_surface_type: string;
};

export const defaultValues = {
  track_id: '',
  identifier: '',
  turns: '',
  length: '',
  svg_url: '',
  satellite_url: '',
  track_type: '',
  pit_road_surface_type: '',
  track_surface_type: '',
};

export const useInitializeFormFieldValues = (trackConfiguration: TrackConfiguration | undefined, form: any) => {
  useEffect(() => {
    if (!trackConfiguration) return;

    form.setValue('track_id', trackConfiguration.track_id);
    form.setValue('identifier', trackConfiguration.identifier);
    form.setValue('turns', numberToString(trackConfiguration.turns));
    form.setValue('length', numberToString(trackConfiguration.length));
    form.setValue('svg_url', trackConfiguration.svg_url ?? '');
    form.setValue('satellite_url', trackConfiguration.satellite_url ?? '');
    form.setValue('track_type', trackConfiguration.track_type ?? '');
    form.setValue('pit_road_surface_type', trackConfiguration.pit_road_surface_type ?? '');
    form.setValue('track_surface_type', trackConfiguration.track_surface_type ?? '');
  }, [trackConfiguration, form]);
};

export const formatPayloadFromFormData = (formData: FormData): TrackConfigurationInput => {
  return {
    track_id: formData.track_id,
    identifier: formData.identifier,
    turns: stringToNumber(formData.turns),
    length: stringToNumber(formData.length),
    svg_url: formData.svg_url || null,
    satellite_url: formData.satellite_url || null,
    track_type: formData.track_type,
    pit_road_surface_type: formData.pit_road_surface_type,
    track_surface_type: formData.track_surface_type,
  };
};
