import { useEffect } from 'react';

import { Driver, DriverInput } from 'types';

export const fieldsConfig = {
  first_name: {
    name: 'first_name',
    label: 'First Name',
    validation: {
      required: true,
    },
  },
  last_name: {
    name: 'last_name',
    label: 'Last Name',
    validation: {
      required: true,
    },
  },
  email: {
    name: 'email',
    label: 'Email',
  },
  birthdate: {
    name: 'birthdate',
    label: 'Birthdate',
    validation: {
      pattern: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/,
    },
    validationMessages: {
      pattern: 'Must be in the format "yyyy-mm-ddThh:mm:ss.SSSZ"'
    },
  },
  gender: {
    name: 'gender',
    label: 'Gender',
    validation: {
      pattern: /^(male|female)$/,
    },
    validationMessages: {
      pattern: 'Must be one of: male, female',
    },
  },
  photo_url: {
    name: 'photo_url',
    label: 'Photo URL',
  },
  verified: {
    name: 'verified',
    label: 'Verified',
  },
};

export interface FormData {
  first_name: string;
  last_name: string;
  email: string;
  birthdate: string;
  gender: string;
  photo_url: string;
  verified: string;
};

export const defaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  birthdate: '',
  gender: '',
  photo_url: '',
  verified: '',
};

export const useInitializeFormFieldValues = (driver: Driver | undefined, form: any) => {
  useEffect(() => {
    if (!driver) return;

    form.setValue('first_name', driver.first_name);
    form.setValue('last_name', driver.last_name);
    form.setValue('email', driver.email ?? '');
    form.setValue('birthdate', driver.birthdate ?? '');
    form.setValue('gender', driver.gender);
    form.setValue('photo_url', driver.photo_url ?? '');
    form.setValue('verified', String(driver.verified));
  }, [driver, form]);
};

export const formatPayloadFromFormData = (formData: FormData): DriverInput => {
  return {
    first_name: formData.first_name,
    last_name: formData.last_name,
    email: formData.email || null,
    birthdate: formData.birthdate || null,
    gender: formData.gender || null,
    photo_url: formData.photo_url || null,
    verified: formData.verified === 'true',
  };
};
