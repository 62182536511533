import _ from 'lodash';

import ValidationMessage from '../ValidationMessage';

export const showValidationMessage = (formState: any, name: string, validationMessages: any) => {
  const type = _.get(formState, `errors.${name}.type`);
  if (!type) return null;

  if (type === 'required') return <ValidationMessage message="Required" />;
  return <ValidationMessage message={validationMessages[type]} />;
};
