import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetTracksQuery } from 'reducers/tracks';
import { useCreateTrackConfigurationMutation } from 'reducers/track-configurations';
import TrackConfigurationFormFields from 'features/TrackConfiguration/TrackConfigurationFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  formatPayloadFromFormData,
} from 'features/TrackConfiguration/trackConfigurationFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function TrackConfigurationNew() {
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });

  const { trackId } = useParams();

  const {
    isLoading: tracksLoading,
    error: tracksError,
    data: tracksData,
  } = useGetTracksQuery({});

  const [
    createTrackConfiguration,
    {
      isLoading: createTrackConfigurationLoading,
      isSuccess: createTrackConfigurationSuccess,
    },
  ] = useCreateTrackConfigurationMutation();

  React.useEffect(() => {
    form.setValue('track_id', trackId!);
  }, [form, trackId]);

  React.useEffect(() => {
    if (createTrackConfigurationSuccess) navigate(-1);
  }, [createTrackConfigurationSuccess, navigate, trackId]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    await createTrackConfiguration(payload);
  };

  if (tracksLoading) return <LoadingIndicator />;
  if (tracksError) throw tracksError;

  return (
    <>
      <PageHeader title="Add Track Configuration" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <TrackConfigurationFormFields
            fieldsConfig={fieldsConfig}
            tracks={tracksData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={createTrackConfigurationLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
