import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

interface FieldConfig {
  name: string;
  label?: string;
  helpText?: string;
}

interface Props {
  config: FieldConfig;
  disabled?: boolean;
}

function CheckboxField(props: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.config.name}
      control={control}
      render={({ field, formState }) => {
        return (
          <Form.Group className="mb-3">
            <Form.Label>{props.config.label}</Form.Label>
            <Form.Check
              {...field}
              checked={field.value}
              isInvalid={!_.isEmpty(formState.errors[props.config.name])}
              disabled={props.disabled}
            />
            {props.config.helpText && (
              <Form.Text className="text-muted">
                {props.config.helpText}
              </Form.Text>
            )}
          </Form.Group>
        );
      }}
    />
  );
};

CheckboxField.defaultProps = {
  disabled: false,
};

export default CheckboxField;
