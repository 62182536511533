import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { dateFilter, textFilter } from 'react-bootstrap-table2-filter';
import _ from 'lodash';

import LoadingIndicator from 'components/LoadingIndicator';
import SectionHeader from 'components/SectionHeader';
import BootstrapTable from 'components/BootstrapTable';
import { useGetSessionsQuery } from 'reducers/sessions';

export default function EventSessionList() {
  const navigate = useNavigate();
  const { seriesId, eventId } = useParams();

  const {
    isLoading: sessionsLoading,
    error: sessionsError,
    data: sessionsData,
  } = useGetSessionsQuery({ event_id: eventId });

  if (sessionsLoading) return <LoadingIndicator />;

  if (sessionsError) throw sessionsError;

  const sessionColumns = [
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      formatter: (cell: string, row: any) => {
        const type = _.toUpper(row.type[0]);
        return `${type}${row.number}`;
      },
      filter: textFilter(),
      headerStyle: {
        width: 120,
      },
    },
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter(),
    },
    {
      dataField: 'scheduled_start',
      text: 'Scheduled Start',
      sort: true,
      filter: dateFilter(),
      formatter: (cell: string | null) => cell && new Date(cell).toLocaleString(),
      headerStyle: {
        width: 225,
      },
    },
    {
      dataField: 'scheduled_end',
      text: 'Scheduled End',
      sort: true,
      filter: dateFilter(),
      formatter: (cell: string | null) => cell && new Date(cell).toLocaleString(),
      headerStyle: {
        width: 225,
      },
    },
    {
      dataField: 'actual_start',
      text: 'Actual Start',
      sort: true,
      filter: dateFilter(),
      formatter: (cell: string | null) => cell && new Date(cell).toLocaleString(),
      headerStyle: {
        width: 225,
      },
    },
    {
      dataField: 'actual_end',
      text: 'Actual End',
      sort: true,
      filter: dateFilter(),
      formatter: (cell: string | null) => cell && new Date(cell).toLocaleString(),
      headerStyle: {
        width: 225,
      },
    },
  ];

  const sessionRows = sessionsData!.data.map((session: any) => ({
    _rowLink: `/series/${seriesId}/events/${eventId}/sessions/${session.id}`,
    ...session,
  }));

  return (
    <>
      <SectionHeader title="Sessions">
        <Button size="sm" onClick={() => navigate(`/series/${seriesId}/events/${eventId}/sessions/new`)}>Add session</Button>
      </SectionHeader>

      <BootstrapTable
        cols={sessionColumns}
        rows={sessionRows}
        defaultSorted={[{ dataField: 'type', order: 'asc' }]}
      />
    </>
  );
};
