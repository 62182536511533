import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

import type {
  Event,
  EventInput,
  EventSearchQuery,
  EventSearchResult,
} from 'types';
import { BASE_URL } from 'config';

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Event'],
  endpoints: (build) => ({
    getEvents: build.query<EventSearchResult, EventSearchQuery>({
      query: (queryParams) => `events?${qs.stringify(queryParams)}`,
      providesTags: (result) =>
        result!.data
          ? [
            ...result!.data.map(({ id }) => ({ type: 'Event' as const, id })),
            { type: 'Event', id: 'LIST' },
          ]
          : [{ type: 'Event', id: 'LIST' }],
    }),
    getEventById: build.query<Event, string>({
      query: (id) => `events/${id}`,
      providesTags: (result, error, id) => [{ type: 'Event', id }],
    }),
    createEvent: build.mutation<Event, EventInput>({
      query: (body) => {
        return {
          url: 'events',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'Event', id: 'LIST' }],
    }),
    updateEvent: build.mutation<Event, EventInput>({
      query: (body) => {
        return {
          url: `events/${body.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Event', id }],
    }),
    deleteEvent: build.mutation<void, string>({
      query: (id) => ({
        url: `events/${id}`,
        method: 'DELETE',
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Event', id }],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetEventByIdQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
} = eventsApi;
