import React from 'react';
import { Col, Row } from 'react-bootstrap';

import ImageUploadField from 'components/FormFields/ImageUploadField';
import TextField from 'components/FormFields/TextField';
import SelectField from 'components/FormFields/SelectField';
import { Track } from 'types';
import { fieldsConfig } from '../trackConfigurationFormConfig';

interface Props {
  fieldsConfig: typeof fieldsConfig;
  tracks: Track[];
  disabled: boolean;
}

function TrackFormFields(props: Props) {
  return (
    <>
      <Row>
        <Col xs={12} md={3}>
          <SelectField
            config={props.fieldsConfig.track_id}
            options={props.tracks.map(t => ({ label: t.short_name, value: t.id }))}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.identifier}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.turns}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.length}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <ImageUploadField
            config={props.fieldsConfig.svg_url}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={6}>
          <ImageUploadField
            config={props.fieldsConfig.satellite_url}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4}>
          <SelectField
            config={props.fieldsConfig.track_type}
            options={[
              { value: 'oval', label: 'Oval' },
              { value: 'road_course', label: 'Road Course' },
              { value: 'street', label: 'Street' },
            ]}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <SelectField
            config={props.fieldsConfig.track_surface_type}
            options={[
              { value: 'asphalt', label: 'Asphalt' },
              { value: 'concrete', label: 'Concrete' },
              { value: 'dirt', label: 'Dirt' },
            ]}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <SelectField
            config={props.fieldsConfig.pit_road_surface_type}
            options={[
              { value: 'asphalt', label: 'Asphalt' },
              { value: 'concrete', label: 'Concrete' },
              { value: 'dirt', label: 'Dirt' },
            ]}
            disabled={props.disabled}
          />
        </Col>
      </Row>
    </>
  );
};

TrackFormFields.defaultProps = {
  disabled: false,
};

export default TrackFormFields;
