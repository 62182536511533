import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetOrganizationByIdQuery, useDeleteOrganizationMutation } from 'reducers/organizations';
import { useGetSeriesQuery } from 'reducers/series';
import OrganizationFormFields from 'features/Organization/OrganizationFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
} from 'features/Organization/organizationFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import DeleteConfirmationButton from 'components/DeleteConfirmationButton';

export default function OrganizationDetail() {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getOrganizationLoading,
    error: getOrganizationError,
    data: organizationData,
  } = useGetOrganizationByIdQuery(organizationId!);

  const {
    isLoading: getSeriesLoading,
    error: getSeriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const [
    deleteOrganization,
    {
      isLoading: deleteOrganizationLoading,
      error: deleteOrganizationError,
      isSuccess: deleteOrganizationSuccess,
    },
  ] = useDeleteOrganizationMutation();

  useInitializeFormFieldValues(organizationData, form);

  React.useEffect(() => {
    if (deleteOrganizationSuccess) navigate(-1);
  }, [deleteOrganizationSuccess, navigate]);

  if (
    getOrganizationLoading ||
    getSeriesLoading ||
    deleteOrganizationLoading
  ) return <LoadingIndicator />;

  if (
    getOrganizationError ||
    getSeriesError ||
    deleteOrganizationError
  ) throw (
    getOrganizationError ||
    getSeriesError ||
    deleteOrganizationError
  );

  return (
    <>
      <PageHeader title={organizationData!.name}>
        <Button onClick={() => navigate(`/organizations/${organizationId}/merge`)}>Merge organization</Button>
        <Button onClick={() => navigate(`/organizations/${organizationId}/edit`)}>Edit organization</Button>
        <DeleteConfirmationButton buttonText="Delete organization" action={() => deleteOrganization(organizationId!)} />
      </PageHeader>

      <FormProvider {...form}>
        <Form>
          <OrganizationFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
            disabled
          />
        </Form>
      </FormProvider>
    </>
  );
}
