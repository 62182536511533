import { ENV } from '../config';

export const fetchUserPermissions = async () => {
  if (ENV === 'local') return ['view', 'modify'];

  // TODO: Make this work locally with the mock platform server
  const res = await fetch('/auth/list-user-permissions');
  const { data: permissions } = await res.json();
  return permissions.map((p: { name: string }) => p.name);
};
