import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetSeriesQuery } from 'reducers/series';
import { useCreateOrganizationMutation } from 'reducers/organizations';
import OrganizationFormFields from 'features/Organization/OrganizationFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  formatPayloadFromFormData,
} from 'features/Organization/organizationFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function OrganizationNew() {
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: seriesLoading,
    error: seriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const [
    createOrganization,
    {
      isLoading: createOrganizationLoading,
      isSuccess: createOrganizationSuccess,
    },
  ] = useCreateOrganizationMutation();

  React.useEffect(() => {
    if (createOrganizationSuccess) navigate(-1);
  }, [createOrganizationSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    await createOrganization(payload);
  };

  if (seriesLoading) return <LoadingIndicator />;
  if (seriesError) throw seriesError;

  return (
    <>
      <PageHeader title="Add Organization" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <OrganizationFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={createOrganizationLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
