import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useCreateDataSourceMutation } from 'reducers/data-sources';
import DataSourceFormFields from '../DataSourceFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  formatPayloadFromFormData,
} from 'features/DataSource/dataSourceFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function DataSourceNew() {
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });

  const [
    createDataSource,
    {
      isLoading: createDataSourceLoading,
      isSuccess: createDataSourceSuccess,
      error: createDataSourceError,
    },
  ] = useCreateDataSourceMutation();

  // Handle successful form submission
  React.useEffect(() => {
    if (createDataSourceSuccess) navigate(-1);
  }, [createDataSourceSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    await createDataSource(payload);
  };

  if (createDataSourceLoading) return <LoadingIndicator />;

  if (createDataSourceError) throw createDataSourceError;

  return (
    <>
      <PageHeader title="Create Data Source" />

      <FormProvider {...form}>
        <Form>
          <DataSourceFormFields fieldsConfig={fieldsConfig} />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={createDataSourceLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
}
