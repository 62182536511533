import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

import type {
  SeriesAlias,
  SeriesAliasInput,
  SeriesAliasSearchQuery,
  SeriesAliasSearchResult,
} from 'types';
import { BASE_URL } from 'config';

export const seriesAliasesApi = createApi({
  reducerPath: 'seriesAliasesApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['SeriesAlias'],
  endpoints: (build) => ({
    getSeriesAliases: build.query<SeriesAliasSearchResult, SeriesAliasSearchQuery>({
      query: (queryParams) => `series-aliases?${qs.stringify(queryParams)}`,
      providesTags: (result) =>
        result!.data
          ? [
            ...result!.data.map(({ id }) => ({ type: 'SeriesAlias' as const, id })),
            { type: 'SeriesAlias', id: 'LIST' },
          ]
          : [{ type: 'SeriesAlias', id: 'LIST' }],
    }),
    getSeriesAliasById: build.query<SeriesAlias, string>({
      query: (id) => `series-aliases/${id}`,
      providesTags: (result, error, id) => [{ type: 'SeriesAlias', id }],
    }),
    createSeriesAlias: build.mutation<SeriesAlias, SeriesAliasInput>({
      query: (body) => {
        return {
          url: 'series-aliases',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'SeriesAlias', id: 'LIST' }],
    }),
    updateSeriesAlias: build.mutation<SeriesAlias, SeriesAliasInput>({
      query: (body) => ({
        url: `series-aliases/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'SeriesAlias', id }],
    }),
    deleteSeriesAlias: build.mutation<void, string>({
      query: (id) => ({
        url: `series-aliases/${id}`,
        method: 'DELETE',
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: (result, error, id) => [{ type: 'SeriesAlias', id }],
    }),
  }),
});

export const {
  useGetSeriesAliasesQuery,
  useGetSeriesAliasByIdQuery,
  useCreateSeriesAliasMutation,
  useUpdateSeriesAliasMutation,
  useDeleteSeriesAliasMutation,
} = seriesAliasesApi;
