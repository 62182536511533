import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetSeriesQuery } from 'reducers/series';
import { useGetSeriesByIdQuery, useUpdateSeriesMutation } from 'reducers/series';
import SeriesFormFields from 'features/Series/SeriesFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
  formatPayloadFromFormData,
} from 'features/Series/seriesFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function SeriesEdit() {
  const navigate = useNavigate();
  const { seriesId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getSeriesLoading,
    error: getSeriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const {
    isLoading: getSerieLoading,
    error: getSerieError,
    data: serieData,
  } = useGetSeriesByIdQuery(seriesId!);

  const [
    updateSeries,
    {
      isLoading: updateSeriesLoading,
      isSuccess: updateSeriesSuccess,
      error: updateSeriesError,
    },
  ] = useUpdateSeriesMutation();

  useInitializeFormFieldValues(serieData, form);

  // Handle successful form submission
  React.useEffect(() => {
    if (updateSeriesSuccess) navigate(-1);
  }, [updateSeriesSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    payload.id = seriesId;

    await updateSeries(payload);
  };

  if (
    getSerieLoading ||
    getSeriesLoading ||
    updateSeriesLoading
  ) return <LoadingIndicator />;

  if (
    getSerieError ||
    getSeriesError ||
    updateSeriesError
  ) throw (
    getSerieError ||
    getSeriesError ||
    updateSeriesError
  );

  return (
    <>
      <PageHeader title="Edit Series" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <SeriesFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={updateSeriesLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
