import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import _ from 'lodash';

import LoadingIndicator from 'components/LoadingIndicator';
import SectionHeader from 'components/SectionHeader';
import BootstrapTable from 'components/BootstrapTable';
import { useGetTrackConfigurationsQuery } from 'reducers/track-configurations';

function TrackConfigurationList() {
  const navigate = useNavigate();
  const { trackId } = useParams();

  const {
    isLoading: trackConfigurationsLoading,
    error: trackConfigurationsError,
    data: trackConfigurationsData,
  } = useGetTrackConfigurationsQuery({ track_id: trackId });

  if (trackConfigurationsLoading) return <LoadingIndicator />;

  if (trackConfigurationsError) throw trackConfigurationsError;

  const trackConfigurationColumns = [
    {
      dataField: 'identifier',
      text: 'Identifier',
      sort: true,
      filter: textFilter(),
      headerStyle: {
        width: 175,
      },
    },
    {
      dataField: 'turns',
      text: 'Turns',
      sort: true,
      filter: textFilter(),
      headerStyle: {
        width: 175,
      },
    },
    {
      dataField: 'length',
      text: 'Length',
      sort: true,
      filter: textFilter(),
      headerStyle: {
        width: 175,
      },
    },
    {
      dataField: 'track_type',
      text: 'Type',
      sort: true,
      filter: selectFilter({
        options:
          [
            { value: 'oval', label: 'Oval' },
            { value: 'road_course', label: 'Road Course' },
            { value: 'street', label: 'Street' },
          ]
      }),
      formatter: (cell: any) => _.startCase(cell),
      headerStyle: {
        width: 175,
      },
    },
  ];

  const trackConfigurationRows = _.chain(trackConfigurationsData!.data)
    .map((trackConfiguration: any) => ({
      _rowLink: `/tracks/${trackId}/configurations/${trackConfiguration.id}`,
      ...trackConfiguration,
    }))
    .orderBy([
      (item) => item.identifier,
    ])
    .value();

  return (
    <>
      <SectionHeader title="Configurations">
        <Button size="sm" onClick={() => navigate(`/tracks/${trackId}/configurations/new`)}>Add configuration</Button>
      </SectionHeader>

      <BootstrapTable
        cols={trackConfigurationColumns}
        rows={trackConfigurationRows}
        defaultSorted={[{ dataField: 'identifier', order: 'asc' }]}
      />
    </>
  );
};

export default TrackConfigurationList;
