import PlatformToolbar from '@apex/react-toolkit/components/PlatformToolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import { ENV } from 'config';
import ErrorBoundary from 'components/ErrorBoundary';

import Navbar from 'components/Navbar';
import Breadcrumbs from 'components/Breadcrumbs';

export default function Layout() {
  const location = useLocation();

  if (location.pathname === '/') return <Navigate to="/events" replace={true} />

  return (
    <>
      <PlatformToolbar
        icon={<FontAwesomeIcon icon="screwdriver-wrench" />}
        isTesting={ENV === 'local'}
        name="Veritas"
      />
      <Navbar />

      <main className="mt-4">
        <Container>
          <ErrorBoundary>
            <Breadcrumbs />
            <Outlet />
          </ErrorBoundary>
        </Container>
      </main>
    </>
  );
};
