import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetSeriesQuery } from 'reducers/series';
import { useGetOrganizationByIdQuery, useUpdateOrganizationMutation } from 'reducers/organizations';
import OrganizationFormFields from 'features/Organization/OrganizationFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
  formatPayloadFromFormData,
} from 'features/Organization/organizationFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function OrganizationEdit() {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getSeriesLoading,
    error: getSeriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const {
    isLoading: getOrganizationLoading,
    error: getOrganizationError,
    data: organizationData,
  } = useGetOrganizationByIdQuery(organizationId!);

  const [
    updateOrganization,
    {
      isLoading: updateOrganizationLoading,
      isSuccess: updateOrganizationSuccess,
      error: updateOrganizationError,
    },
  ] = useUpdateOrganizationMutation();

  useInitializeFormFieldValues(organizationData, form);

  // Handle successful form submission
  React.useEffect(() => {
    if (updateOrganizationSuccess) navigate(-1);
  }, [updateOrganizationSuccess, navigate, organizationId]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    payload.id = organizationId;

    await updateOrganization(payload);
  };

  if (
    getSeriesLoading ||
    getOrganizationLoading ||
    updateOrganizationLoading
  ) return <LoadingIndicator />;

  if (
    getSeriesError ||
    getOrganizationError ||
    updateOrganizationError
  ) throw (
    getSeriesError ||
    getOrganizationError ||
    updateOrganizationError
  );

  return (
    <>
      <PageHeader title="Edit Organization" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <OrganizationFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={updateOrganizationLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
