import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { basename } from 'config';

export default function NavbarWrapper() {
  return (
    <Navbar bg="light" expand="lg" variant="light">
      <Container>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href={`${basename}/events`}>Events</Nav.Link>
            <Nav.Link href={`${basename}/series`}>Series</Nav.Link>
            <Nav.Link href={`${basename}/tracks`}>Tracks</Nav.Link>
            <Nav.Link href={`${basename}/organizations`}>Organizations</Nav.Link>
            <Nav.Link href={`${basename}/drivers`}>Drivers</Nav.Link>
            <Nav.Link href={`${basename}/driver-awards`}>Driver Awards</Nav.Link>
            <Nav.Link href={`${basename}/data-sources`}>Data Sources</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
