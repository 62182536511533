import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

import type {
  Session,
  SessionInput,
  SessionSearchQuery,
  SessionSearchResult,
} from 'types';
import { BASE_URL } from 'config';

export const sessionsApi = createApi({
  reducerPath: 'sessionsApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Session'],
  endpoints: (build) => ({
    getSessions: build.query<SessionSearchResult, SessionSearchQuery>({
      query: (queryParams) => `sessions?${qs.stringify(queryParams)}`,
      providesTags: (result) =>
        result!.data
          ? [
            ...result!.data.map(({ id }) => ({ type: 'Session' as const, id })),
            { type: 'Session', id: 'LIST' },
          ]
          : [{ type: 'Session', id: 'LIST' }],
    }),
    getSessionById: build.query<Session, string>({
      query: (id) => `sessions/${id}`,
      providesTags: (result, error, id) => [{ type: 'Session', id }],
    }),
    createSession: build.mutation<Session, SessionInput>({
      query: (body) => {
        return {
          url: 'sessions',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'Session', id: 'LIST' }],
    }),
    updateSession: build.mutation<Session, SessionInput>({
      query: (body) => ({
        url: `sessions/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Session', id }],
    }),
    deleteSession: build.mutation<void, string>({
      query: (id) => ({
        url: `sessions/${id}`,
        method: 'DELETE',
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Session', id }],
    }),
  }),
});

export const {
  useGetSessionsQuery,
  // useGetSessionsByEventIdQuery,
  useGetSessionByIdQuery,
  useCreateSessionMutation,
  useUpdateSessionMutation,
  useDeleteSessionMutation,
} = sessionsApi;
