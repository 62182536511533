import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetSeriesAliasByIdQuery, useDeleteSeriesAliasMutation } from 'reducers/series-aliases';
import { useGetSeriesQuery } from 'reducers/series';
import SeriesAliasFormFields from 'features/SeriesAlias/SeriesAliasFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
} from 'features/SeriesAlias/seriesAliasFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import DeleteConfirmationButton from 'components/DeleteConfirmationButton';

export default function SeriesAliasDetail() {
  const navigate = useNavigate();
  const { seriesId, seriesAliasId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getSeriesAliasLoading,
    error: getSeriesAliasError,
    data: seriesAliasData,
  } = useGetSeriesAliasByIdQuery(seriesAliasId!);

  const {
    isLoading: getSeriesLoading,
    error: getSeriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const [
    deleteSeriesAlias,
    {
      isLoading: deleteSeriesAliasLoading,
      error: deleteSeriesAliasError,
      isSuccess: deleteSeriesAliasSuccess,
    },
  ] = useDeleteSeriesAliasMutation();

  useInitializeFormFieldValues(seriesAliasData, form);

  React.useEffect(() => {
    if (deleteSeriesAliasSuccess) navigate(-1);
  }, [deleteSeriesAliasSuccess, navigate]);

  if (getSeriesAliasLoading ||
    getSeriesLoading ||
    deleteSeriesAliasLoading
  ) return <LoadingIndicator />;

  if (getSeriesAliasError ||
    getSeriesError ||
    deleteSeriesAliasError
  ) throw (
    getSeriesAliasError ||
    getSeriesError ||
    deleteSeriesAliasError
  );

  return (
    <>
      <PageHeader title={seriesAliasData!.name}>
        <Button onClick={() => navigate(`/series/${seriesId}/aliases/${seriesAliasId}/edit`)}>Edit alias</Button>
        <DeleteConfirmationButton buttonText="Delete alias" action={() => deleteSeriesAlias(seriesAliasId!)} />
      </PageHeader>

      <FormProvider {...form}>
        <Form>
          <SeriesAliasFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
            disabled
          />
        </Form>
      </FormProvider>
    </>
  );
}
