import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { textFilter } from 'react-bootstrap-table2-filter';
import _ from 'lodash';

import { useGetSeriesAliasesQuery } from 'reducers/series-aliases';
import LoadingIndicator from 'components/LoadingIndicator';
import SectionHeader from 'components/SectionHeader';
import BootstrapTable from 'components/BootstrapTable';

function SeriesAliasList() {
  const navigate = useNavigate();
  const { seriesId } = useParams();

  const {
    isLoading: seriesAliasesLoading,
    error: seriesAliasesError,
    data: seriesAliasesData,
  } = useGetSeriesAliasesQuery({ series_id: seriesId });

  if (seriesAliasesLoading) return <LoadingIndicator />;

  if (seriesAliasesError) throw seriesAliasesError;

  const seriesAliasesColumns = [
    {
      dataField: 'year',
      text: 'Year',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter(),
    },
  ];

  const seriesAliasesRows = _.chain(seriesAliasesData!.data)
    .map((seriesAlias: any) => ({
      _rowLink: `/series/${seriesId}/aliases/${seriesAlias.id}`,
      year: seriesAlias.year,
      name: seriesAlias.name,
    }))
    .orderBy([
      (item) => item.year,
    ])
    .value();

  const defaultSorted = [{ dataField: 'year', order: 'asc' }];

  return (
    <>
      <SectionHeader title="Aliases">
        <Button size="sm" onClick={() => navigate(`/series/${seriesId}/aliases/new`)}>Add alias</Button>
      </SectionHeader>

      <BootstrapTable
        cols={seriesAliasesColumns}
        rows={seriesAliasesRows}
        defaultSorted={defaultSorted}
      />
    </>
  );
};

export default SeriesAliasList;
