import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Form, Button, Tab } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetEventsQuery } from 'reducers/events';
import { useGetSessionByIdQuery, useDeleteSessionMutation } from 'reducers/sessions';
import SessionFormFields from 'features/Session/SessionFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
} from 'features/Session/sessionFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import DeleteConfirmationButton from 'components/DeleteConfirmationButton';
import SessionResultList from 'features/SessionResult/SessionResultList';
import DriverAwardList from 'features/DriverAward/DriverAwardList';
import PersistentTabs from 'components/PersistentTabs';

export default function SessionDetail() {
  const navigate = useNavigate();
  const { seriesId, eventId, sessionId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getSessionLoading,
    error: getSessionError,
    data: sessionData,
  } = useGetSessionByIdQuery(sessionId!);

  const {
    isLoading: getEventsLoading,
    error: getEventsError,
    data: eventsData,
  } = useGetEventsQuery({ series_id: seriesId });

  const [
    deleteSession,
    {
      isLoading: deleteSessionLoading,
      error: deleteSessionError,
      isSuccess: deleteSessionSuccess,
    },
  ] = useDeleteSessionMutation();

  useInitializeFormFieldValues(sessionData, form);

  React.useEffect(() => {
    if (deleteSessionSuccess) navigate(-1);
  }, [deleteSessionSuccess, navigate]);

  if (
    getSessionLoading ||
    getEventsLoading ||
    deleteSessionLoading
  ) return <LoadingIndicator />;

  if (
    getSessionError ||
    getEventsError ||
    deleteSessionError
  ) throw (
    getSessionError ||
    getEventsError ||
    deleteSessionError
  )

  return (
    <>
      <PageHeader title={sessionData!.name}>
        <Button onClick={() => navigate(`/series/${seriesId}/events/${eventId}/sessions/${sessionId}/edit`)}>Edit session</Button>
        <DeleteConfirmationButton buttonText="Delete session" action={() => deleteSession(sessionId!)} />
      </PageHeader>

      <PersistentTabs
        defaultActiveKey="details"
        className="mb-3"
      >
        <Tab eventKey="details" title="Details">
          <FormProvider {...form}>
            <Form>
              <SessionFormFields
                fieldsConfig={fieldsConfig}
                events={eventsData!.data}
                disabled
              />
            </Form>
          </FormProvider>
        </Tab>

        {sessionData!.type === 'race' && (
          <Tab eventKey="awards" title="Awards">
            <DriverAwardList title="Awards" sessionId={sessionId} disabledColumns={['session.id']} />
          </Tab>
        )}

        <Tab eventKey="results" title="Results">
          <SessionResultList />
        </Tab>
      </PersistentTabs>
    </>
  );
}
