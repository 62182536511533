import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

import type {
  Driver,
  DriverInput,
  DriverMergeInput,
  DriverSearchQuery,
  DriverSearchResult,
} from 'types';
import { BASE_URL } from 'config';

export const driversApi = createApi({
  reducerPath: 'driversApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Driver'],
  endpoints: (build) => ({
    getDrivers: build.query<DriverSearchResult, DriverSearchQuery>({
      query: (queryParams) => `drivers?${qs.stringify(queryParams)}`,
      providesTags: (result) =>
        result!.data
          ? [
            ...result!.data.map(({ id }) => ({ type: 'Driver' as const, id })),
            { type: 'Driver', id: 'LIST' },
          ]
          : [{ type: 'Driver', id: 'LIST' }],
    }),
    getDriverById: build.query<Driver, string>({
      query: (id) => `drivers/${id}`,
      providesTags: (result, error, id) => [{ type: 'Driver', id }],
    }),
    createDriver: build.mutation<Driver, DriverInput>({
      query: (body) => {
        return {
          url: 'drivers',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'Driver', id: 'LIST' }],
    }),
    updateDriver: build.mutation<Driver, DriverInput>({
      query: (body) => ({
        url: `drivers/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Driver', id }],
    }),
    deleteDriver: build.mutation<void, string>({
      query: (id) => ({
        url: `drivers/${id}`,
        method: 'DELETE',
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Driver', id }],
    }),
    mergeDriver: build.mutation<void, DriverMergeInput>({
      query: (body) => ({
        url: 'merge/driver',
        method: 'POST',
        body,
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: [{ type: 'Driver', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetDriversQuery,
  useGetDriverByIdQuery,
  useCreateDriverMutation,
  useUpdateDriverMutation,
  useDeleteDriverMutation,
  useMergeDriverMutation,
} = driversApi;
