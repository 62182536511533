import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetTrackConfigurationByIdQuery, useDeleteTrackConfigurationMutation } from 'reducers/track-configurations';
import { useGetTracksQuery } from 'reducers/tracks';
import TrackConfigurationFormFields from 'features/TrackConfiguration/TrackConfigurationFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
} from 'features/TrackConfiguration/trackConfigurationFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import DeleteConfirmationButton from 'components/DeleteConfirmationButton';

export default function TrackConfigurationDetail() {
  const navigate = useNavigate();
  const { trackId, trackConfigurationId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getTrackConfigurationLoading,
    error: getTrackConfigurationError,
    data: trackConfigurationData,
  } = useGetTrackConfigurationByIdQuery(trackConfigurationId!);

  const {
    isLoading: getTracksLoading,
    error: getTracksError,
    data: tracksData,
  } = useGetTracksQuery({});

  const [
    deleteTrackConfiguration,
    {
      isLoading: deleteTrackConfigurationLoading,
      error: deleteTrackConfigurationError,
      isSuccess: deleteTrackConfigurationSuccess,
    },
  ] = useDeleteTrackConfigurationMutation();

  useInitializeFormFieldValues(trackConfigurationData, form);

  React.useEffect(() => {
    if (deleteTrackConfigurationSuccess) navigate(-1);
  }, [deleteTrackConfigurationSuccess, navigate, trackId]);

  if (getTrackConfigurationLoading ||
    getTracksLoading ||
    deleteTrackConfigurationLoading
  ) return <LoadingIndicator />;

  if (getTrackConfigurationError ||
    getTracksError ||
    deleteTrackConfigurationError
  ) throw (
    getTrackConfigurationError ||
    getTracksError ||
    deleteTrackConfigurationError
  );

  return (
    <>
      <PageHeader title={trackConfigurationData!.identifier}>
        <Button onClick={() => navigate(`/tracks/${trackId}/configurations/${trackConfigurationId}/edit`)}>Edit configuration</Button>
        <DeleteConfirmationButton buttonText="Delete configuration" action={() => deleteTrackConfiguration(trackConfigurationId!)} />
      </PageHeader>

      <FormProvider {...form}>
        <Form>
          <TrackConfigurationFormFields
            fieldsConfig={fieldsConfig}
            tracks={tracksData!.data}
            disabled
          />
        </Form>
      </FormProvider>
    </>
  );
}
