import { useEffect } from 'react';

import { Award, AwardInput } from 'types';

export const fieldsConfig = {
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  series_id: {
    name: 'series_id',
    label: 'Series',
    validation: {
      required: true,
    },
  },
};

export interface FormData {
  name: string;
  series_id: string;
};

export const defaultValues = {
  name: '',
  series_id: '',
};

export const useInitializeFormFieldValues = (award: Award | undefined, form: any) => {
  useEffect(() => {
    if (!award) return;

    form.setValue('name', award.name);
    form.setValue('series_id', award.series_id);
  }, [award, form]);
};

export const formatPayloadFromFormData = (formData: FormData): AwardInput => {
  return {
    name: formData.name,
    series_id: formData.series_id,
  };
};
