import React from 'react';
import { Col, Row } from 'react-bootstrap';

import TextField from 'components/FormFields/TextField';
import { Series } from 'types';
import { fieldsConfig } from '../seriesFormConfig';

interface Props {
  fieldsConfig: typeof fieldsConfig;
  series: Series[];
  disabled: boolean;
}

function SeriesFormFields(props: Props) {
  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <TextField
            config={props.fieldsConfig.letter}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={6}>
          <TextField
            config={props.fieldsConfig.abbreviation}
            disabled={props.disabled}
          />
        </Col>
      </Row>
    </>
  );
};

SeriesFormFields.defaultProps = {
  disabled: false,
};

export default SeriesFormFields;
