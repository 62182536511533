import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
  formatPayloadFromFormData,
} from 'features/DataSource/dataSourceFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';
import { useGetDataSourceByIdQuery, useUpdateDataSourceMutation } from 'reducers/data-sources';
import DataSourceFormFields from '../DataSourceFormFields';

export default function DataSourceEdit() {
  const navigate = useNavigate();
  const { dataSourceId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getDataSourceLoading,
    error: getDataSourceError,
    data: event,
  } = useGetDataSourceByIdQuery(dataSourceId!);

  const [
    updateEvent,
    {
      isLoading: updateDataSourceLoading,
      isSuccess: updateDataSourceSuccess,
      error: updateDataSourceError,
    },
  ] = useUpdateDataSourceMutation();

  useInitializeFormFieldValues(event, form);

  // Handle successful form submission
  React.useEffect(() => {
    if (updateDataSourceSuccess) navigate(-1);
  }, [updateDataSourceSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    payload.id = dataSourceId;

    await updateEvent(payload);
  };

  if (
    getDataSourceLoading ||
    updateDataSourceLoading
  ) return <LoadingIndicator />;

  if (
    getDataSourceError ||
    updateDataSourceError
  ) throw (
    getDataSourceError ||
    updateDataSourceError
  );

  return (
    <>
      <PageHeader title="Data Source" />

      <FormProvider {...form}>
        <Form>
          <DataSourceFormFields fieldsConfig={fieldsConfig} />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={updateDataSourceLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
}
