import { useEffect } from 'react';

import { SeriesDataSource, SeriesDataSourceInput } from 'types';

export const fieldsConfig = {
  series_id: {
    name: 'series_id',
    label: 'Series',
    validation: {
      required: true,
    },
  },
  data_source_id: {
    name: 'data_source_id',
    label: 'Data Source',
    validation: {
      required: true,
    },
  },
  is_primary: {
    name: 'is_primary',
    label: 'Primary',
    validation: {
      required: true,
    },
  },
  is_test: {
    name: 'is_test',
    label: 'Test',
    validation: {
      required: true,
    },
  },
  year: {
    name: 'year',
    label: 'Year',
    validation: {
      pattern: /^[0-9]{4}$/,
    },
  },
};

export interface FormData {
  series_id: string;
  data_source_id: string;
  is_primary: boolean;
  is_test: boolean;
  year: string;
};

export const defaultValues = {
  series_id: '',
  data_source_id: '',
  is_primary: false,
  is_test: false,
  year: '',
};

export const useInitializeFormFieldValues = (seriesDataSource: SeriesDataSource | undefined, form: any) => {
  useEffect(() => {
    if (!seriesDataSource) return;

    form.setValue('series_id', seriesDataSource.series_id);
    form.setValue('data_source_id', seriesDataSource.data_source_id);
    form.setValue('is_primary', seriesDataSource.is_primary);
    form.setValue('is_test', seriesDataSource.is_test);
    form.setValue('year', seriesDataSource.year);
  }, [seriesDataSource, form]);
};

export const formatPayloadFromFormData = (formData: FormData): SeriesDataSourceInput => {
  return {
    series_id: formData.series_id,
    data_source_id: formData.data_source_id,
    is_primary: formData.is_primary,
    is_test: formData.is_test,
    year: formData.year ? Number(formData.year) : null,
  };
};
