import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

interface ModalWrapperProps {
  show: boolean;
  onHide: any;
  action: any;
}

function ModalWrapper(props: ModalWrapperProps) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          Are you sure?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-between">
        <Button variant="danger" onClick={props.action}>Delete</Button>
        <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
      </Modal.Body>
    </Modal>
  );
}

interface Props {
  buttonText: string;
  action: any;
  disabled?: boolean;
}

export default function DeleteConfirmationButton(props: Props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button disabled={props.disabled} variant="danger" onClick={() => setModalShow(true)}>
        {props.buttonText}
      </Button>

      <ModalWrapper
        show={modalShow}
        onHide={() => setModalShow(false)}
        action={props.action}
      />
    </>
  );
}
