import React from 'react';
import {
  Accordion,
  Form,
  Row,
  Col,
  Image,
} from 'react-bootstrap';
import _ from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';

import { useGetTrackConfigurationByIdQuery } from 'reducers/track-configurations';
import { useGetTrackByIdQuery } from 'reducers/tracks';
import { Series, Track, TrackConfiguration } from 'types';
import ImageUploadField from 'components/FormFields/ImageUploadField';
import TextField from 'components/FormFields/TextField';
import SelectField from 'components/FormFields/SelectField';
import LoadingIndicator from 'components/LoadingIndicator';
import SectionHeader from 'components/SectionHeader';
import { fieldsConfig } from '../eventFormConfig';
import DateTimeField from 'components/FormFields/DateTimeField';

interface Props {
  fieldsConfig: typeof fieldsConfig;
  series: Series[];
  tracks: Track[];
  trackConfigurations: TrackConfiguration[];
  disabled: boolean;
}

function EventFormFields(props: Props) {
  const { control } = useFormContext();
  const trackId = useWatch({ control, name: 'track_id' });
  const trackConfigurationId = useWatch({ control, name: 'track_configuration_id' });

  const {
    isLoading: getTrackLoading,
    error: getTrackError,
    data: trackData,
  } = useGetTrackByIdQuery(trackId!);

  const {
    isLoading: getTrackConfigurationLoading,
    error: getTrackConfigurationError,
    data: trackConfigurationData,
  } = useGetTrackConfigurationByIdQuery(trackConfigurationId!);

  if (
    getTrackLoading ||
    getTrackConfigurationLoading
  ) return <LoadingIndicator />;

  if (
    getTrackError ||
    getTrackConfigurationError
  ) throw (
    getTrackError ||
    getTrackConfigurationError
  );

  return (
    <>
      <Row>
        <Col xs={12} lg={10}>
          <TextField
            config={props.fieldsConfig.name}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} lg={2}>
          <SelectField
            config={props.fieldsConfig.series_id}
            options={props.series.map(s => ({ label: s.abbreviation, value: s.id }))}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} lg={2}>
          <TextField
            config={props.fieldsConfig.year}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} lg={10}>
          <DateTimeField
            config={props.fieldsConfig.date_time}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} lg={2}>
          <SelectField
            config={props.fieldsConfig.is_test}
            options={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={10}>
          <ImageUploadField
            config={props.fieldsConfig.track_photo_url}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <TextField
            config={props.fieldsConfig.series_event_id}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={6}>
          <TextField
            config={props.fieldsConfig.trd_event_id}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.total_laps}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.duration}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.target_lap_time}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      <SectionHeader title="Track" />

      <Row>
        <Col xs={12} md={3}>
          <SelectField
            config={props.fieldsConfig.track_id}
            options={props.tracks.map(t => ({ label: t.short_name, value: t.id }))}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <Form.Label>City</Form.Label>
          <Form.Control disabled value={trackData!.city} />
        </Col>

        <Col xs={12} md={3}>
          <Form.Label>State</Form.Label>
          <Form.Control disabled value={trackData!.state} />
        </Col>

        <Col xs={12} md={3}>
          <Form.Label>Timezone</Form.Label>
          <Form.Control disabled value={trackData!.timezone_id} />
        </Col>
      </Row>

      <SectionHeader title="Track Configuration" />

      <Row>
        <Col xs={12} md={4}>
          <SelectField
            config={props.fieldsConfig.track_configuration_id}
            options={
              props.trackConfigurations
                .filter(tc => tc.track_id === trackId)
                .map(tc => ({ label: tc.identifier, value: tc.id }))
            }
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <Form.Label>Turns</Form.Label>
          <Form.Control disabled value={trackConfigurationData!.turns} />
        </Col>

        <Col xs={12} md={4}>
          <Form.Label>Length</Form.Label>
          <Form.Control disabled value={trackConfigurationData!.length} />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4}>
          <Form.Label>Type</Form.Label>
          <Form.Control disabled value={_.startCase(trackConfigurationData!.track_type)} />
        </Col>

        <Col xs={12} md={4}>
          <Form.Label>Track Surface</Form.Label>
          <Form.Control disabled value={_.startCase(trackConfigurationData!.track_surface_type)} />
        </Col>

        <Col xs={12} md={4}>
          <Form.Label>Pit Road Surface</Form.Label>
          <Form.Control disabled value={_.startCase(trackConfigurationData!.pit_road_surface_type)} />
        </Col>
      </Row>

      <Row className="mt-3 mb-3">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Track Configuration Images</Accordion.Header>
            <Accordion.Body style={{ backgroundColor: 'lightgrey' }}>
              <Row>
                <Col xs={12} md={6}>
                  <Image src={trackConfigurationData!.svg_url} width="100%" />
                </Col>

                <Col xs={12} md={6}>
                  <Image src={trackConfigurationData!.satellite_url} width="100%" />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </>
  );
}

EventFormFields.defaultProps = {
  disabled: false,
};

export default EventFormFields;
