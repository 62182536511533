import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import { awardsApi } from 'reducers/awards';
import { teamsApi } from 'reducers/teams';
import { dataSourcesApi } from 'reducers/data-sources';
import { driversApi } from 'reducers/drivers';
import { driverAwardsApi } from 'reducers/driver-awards';
import { eventsApi } from 'reducers/events';
import { seriesAliasesApi } from 'reducers/series-aliases';
import { seriesApi } from 'reducers/series';
import { seriesDataSourcesApi } from 'reducers/series-data-sources';
import { sessionResultsApi } from 'reducers/session-results';
import { sessionsApi } from 'reducers/sessions';
import { organizationsApi } from 'reducers/organizations';
import { trackConfigurationsApi } from 'reducers/track-configurations';
import { tracksApi } from 'reducers/tracks';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [awardsApi.reducerPath]: awardsApi.reducer,
    [teamsApi.reducerPath]: teamsApi.reducer,
    [driversApi.reducerPath]: driversApi.reducer,
    [driverAwardsApi.reducerPath]: driverAwardsApi.reducer,
    [dataSourcesApi.reducerPath]: dataSourcesApi.reducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
    [seriesAliasesApi.reducerPath]: seriesAliasesApi.reducer,
    [seriesApi.reducerPath]: seriesApi.reducer,
    [seriesDataSourcesApi.reducerPath]: seriesDataSourcesApi.reducer,
    [sessionResultsApi.reducerPath]: sessionResultsApi.reducer,
    [sessionsApi.reducerPath]: sessionsApi.reducer,
    [organizationsApi.reducerPath]: organizationsApi.reducer,
    [trackConfigurationsApi.reducerPath]: trackConfigurationsApi.reducer,
    [tracksApi.reducerPath]: tracksApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      awardsApi.middleware,
      teamsApi.middleware,
      driversApi.middleware,
      driverAwardsApi.middleware,
      dataSourcesApi.middleware,
      eventsApi.middleware,
      seriesAliasesApi.middleware,
      seriesApi.middleware,
      seriesDataSourcesApi.middleware,
      sessionResultsApi.middleware,
      sessionsApi.middleware,
      organizationsApi.middleware,
      trackConfigurationsApi.middleware,
      tracksApi.middleware,
    ]),
});

// Optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization.
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
