import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

import { showValidationMessage } from 'components/FormFields/helpers';

interface FieldConfig {
  name: string;
  label?: string;
  validation?: any;
  validationMessages?: any;
}

interface Option {
  label: string;
  value: string;
}

interface Props {
  config: FieldConfig;
  options: Option[];
  disabled?: boolean;
}

function Select(props: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.config.name}
      control={control}
      rules={props.config.validation}
      render={({ field, formState }) => {
        return (
          <Form.Group className="mb-3">
            <Form.Label>{props.config.label}</Form.Label>
            <Form.Select
              {...field}
              isInvalid={!_.isEmpty(formState.errors[props.config.name])}
              disabled={props.disabled}
            >
              <option value="">Select...</option>
              {
                props.options
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map(({ label, value }) => <option key={value} value={value}>{label}</option>)
              }
            </Form.Select>
            {showValidationMessage(formState, props.config.name, props.config.validationMessages)}
          </Form.Group>
        );
      }}
    />
  );
};

Select.defaultProps = {
  disabled: false,
};

export default Select;
