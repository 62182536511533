import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

import type {
  DriverAward,
  DriverAwardInput,
  DriverAwardSearchQuery,
  DriverAwardSearchResult,
} from 'types';
import { BASE_URL } from 'config';

export const driverAwardsApi = createApi({
  reducerPath: 'driverAwardsApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['DriverAward'],
  endpoints: (build) => ({
    getDriverAwards: build.query<DriverAwardSearchResult, DriverAwardSearchQuery>({
      query: (queryParams) => `driver-awards?${qs.stringify(queryParams)}`,
      providesTags: (result) =>
        result!.data
          ? [
            ...result!.data.map(({ id }) => ({ type: 'DriverAward' as const, id })),
            { type: 'DriverAward', id: 'LIST' },
          ]
          : [{ type: 'DriverAward', id: 'LIST' }],
    }),
    getDriverAwardById: build.query<DriverAward, string>({
      query: (id) => `driver-awards/${id}`,
      providesTags: (result, error, id) => [{ type: 'DriverAward', id }],
    }),
    createDriverAward: build.mutation<DriverAward, DriverAwardInput>({
      query: (body) => {
        return {
          url: 'driver-awards',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'DriverAward', id: 'LIST' }],
    }),
    updateDriverAward: build.mutation<DriverAward, DriverAwardInput>({
      query: (body) => ({
        url: `driver-awards/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'DriverAward', id }],
    }),
    deleteDriverAward: build.mutation<void, string>({
      query: (id) => ({
        url: `driver-awards/${id}`,
        method: 'DELETE',
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: (result, error, id) => [{ type: 'DriverAward', id }],
    }),
  }),
});

export const {
  useGetDriverAwardsQuery,
  useGetDriverAwardByIdQuery,
  useCreateDriverAwardMutation,
  useUpdateDriverAwardMutation,
  useDeleteDriverAwardMutation,
} = driverAwardsApi;
