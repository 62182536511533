import { useEffect } from 'react';

import { Team, TeamInput } from 'types';

export const fieldsConfig = {
  number: {
    name: 'number',
    label: 'Number',
    validation: {
      required: true,
      pattern: /^\d{1,2}$/,
    },
    validationMessages: {
      pattern: 'Must be one or two digits',
    },
  },
  series_id: {
    name: 'series_id',
    label: 'Series',
    validation: {
      required: true,
    },
  },
  livery_url: {
    name: 'livery_url',
    label: 'Livery URL',
  },
  verified: {
    name: 'verified',
    label: 'Verified',
    validation: {
      required: true,
    },
  },
  organization_id: {
    name: 'organization_id',
    label: 'Organization',
  }
};

export interface FormData {
  number: string;
  series_id: string;
  livery_url: string;
  verified: string;
  organization_id: string;
};

export const defaultValues = {
  number: '',
  series_id: '',
  livery_url: '',
  verified: '',
  organization_id: '',
};

export const useInitializeFormFieldValues = (team: Team | undefined, form: any) => {
  useEffect(() => {
    if (!team) return;

    form.setValue('number', team.number);
    form.setValue('series_id', team.series_id);
    form.setValue('livery_url', team.livery_url ?? '');
    form.setValue('verified', String(team.verified));
    form.setValue('organization_id', team.organization_id ?? '');
  }, [team, form]);
};

export const formatPayloadFromFormData = (formData: FormData): TeamInput => {
  return {
    number: formData.number,
    series_id: formData.series_id,
    livery_url: formData.livery_url || null,
    verified: formData.verified === 'true',
    organization_id: formData.organization_id || null,
  };
};
