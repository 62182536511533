import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetSeriesQuery } from 'reducers/series';
import { useCreateTeamMutation } from 'reducers/teams';
import TeamFormFields from 'features/Team/TeamFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  formatPayloadFromFormData,
} from 'features/Team/teamFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';
import { useGetOrganizationsQuery } from 'reducers/organizations';

export default function TeamNew() {
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });
  const { seriesId } = useParams();

  const {
    isLoading: seriesLoading,
    error: seriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const {
    isLoading: getOrganizationsLoading,
    error: getOrganizationsError,
    data: organizationsData,
  } = useGetOrganizationsQuery({});

  const [
    createTeam,
    {
      isLoading: createTeamLoading,
      isSuccess: createTeamSuccess,
    },
  ] = useCreateTeamMutation();

  React.useEffect(() => {
    form.setValue('series_id', seriesId!);
  }, [form, seriesId]);

  React.useEffect(() => {
    if (createTeamSuccess) navigate(-1);
  }, [createTeamSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    await createTeam(payload);
  };

  if (seriesLoading || getOrganizationsLoading) return <LoadingIndicator />;
  if (seriesError || getOrganizationsError) throw seriesError || getOrganizationsError;

  return (
    <>
      <PageHeader title="Add Team" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <TeamFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
            organizations={organizationsData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={createTeamLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
