import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetSeriesQuery } from 'reducers/series';
import { useGetDriverByIdQuery, useUpdateDriverMutation } from 'reducers/drivers';
import DriverFormFields from 'features/Driver/DriverFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
  formatPayloadFromFormData,
} from 'features/Driver/driverFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function DriverEdit() {
  const navigate = useNavigate();
  const { driverId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getSeriesLoading,
    error: getSeriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const {
    isLoading: getDriverLoading,
    error: getDriverError,
    data: driverData,
  } = useGetDriverByIdQuery(driverId!);

  const [
    updateDriver,
    {
      isLoading: updateDriverLoading,
      isSuccess: updateDriverSuccess,
      error: updateDriverError,
    },
  ] = useUpdateDriverMutation();

  useInitializeFormFieldValues(driverData, form);

  // Handle successful form submission
  React.useEffect(() => {
    if (updateDriverSuccess) navigate(-1);
  }, [updateDriverSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    payload.id = driverId;

    await updateDriver(payload);
  };

  if (
    getSeriesLoading ||
    getDriverLoading ||
    updateDriverLoading
  ) return <LoadingIndicator />;

  if (
    getSeriesError ||
    getDriverError ||
    updateDriverError
  ) throw (
    getSeriesError ||
    getDriverError ||
    updateDriverError
  );

  return (
    <>
      <PageHeader title="Edit Driver" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <DriverFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={updateDriverLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
