import { useEffect } from 'react';

import { Session, SessionInput } from 'types';
import stringToNumber from 'helpers/stringToNumber';
import numberToString from 'helpers/numberToString';

export const fieldsConfig = {
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  event_id: {
    name: 'event_id',
    label: 'Event',
    validation: {
      required: true,
    },
  },
  type: {
    name: 'type',
    label: 'Type',
    validation: {
      required: true,
    },
  },
  number: {
    name: 'number',
    label: 'Number',
    validation: {
      required: true,
      pattern: /^\d+$/,
    },
    validationMessages: {
      pattern: 'Must be a number',
    },
  },
  scheduled_start: {
    name: 'scheduled_start',
    label: 'Scheduled Start',
    validation: {
      required: true,
      pattern: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/,
    },
    validationMessages: {
      pattern: 'Must be in the format "yyyy-mm-ddThh:mm:ss.SSSZ"'
    },
  },
  scheduled_end: {
    name: 'scheduled_end',
    label: 'Scheduled End',
    validation: {
      required: true,
      pattern: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/,
    },
    validationMessages: {
      pattern: 'Must be in the format "yyyy-mm-ddThh:mm:ss.SSSZ"'
    },
  },
  actual_start: {
    name: 'actual_start',
    label: 'Actual Start',
    validation: {
      required: true,
      pattern: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/,
    },
    validationMessages: {
      pattern: 'Must be in the format "yyyy-mm-ddThh:mm:ss.SSSZ"'
    },
  },
  actual_end: {
    name: 'actual_end',
    label: 'Actual End',
    validation: {
      required: true,
      pattern: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/,
    },
    validationMessages: {
      pattern: 'Must be in the format "yyyy-mm-ddThh:mm:ss.SSSZ"'
    },
  },
  podium_award_ceremony_url: {
    name: 'podium_award_ceremony_url',
    label: 'Podium Award Ceremony URL',
  },
};

export interface FormData {
  name: string;
  event_id: string;
  type: string;
  number: string;
  scheduled_start: string;
  scheduled_end: string;
  actual_start: string;
  actual_end: string;
  podium_award_ceremony_url: string;
};

export const defaultValues = {
  name: '',
  event_id: '',
  type: '',
  number: '',
  scheduled_start: '',
  scheduled_end: '',
  actual_start: '',
  actual_end: '',
  podium_award_ceremony_url: '',
};

export const useInitializeFormFieldValues = (session: Session | undefined, form: any) => {
  useEffect(() => {
    if (!session) return;

    form.setValue('name', session.name);
    form.setValue('event_id', session.event_id);
    form.setValue('type', session.type ?? '');
    form.setValue('number', numberToString(session.number));
    form.setValue('scheduled_start', session.scheduled_start ?? '');
    form.setValue('scheduled_end', session.scheduled_end ?? '');
    form.setValue('actual_start', session.actual_start ?? '');
    form.setValue('actual_end', session.actual_end ?? '');
    form.setValue('podium_award_ceremony_url', session.podium_award_ceremony_url ?? '');
  }, [session, form]);
};

export const formatPayloadFromFormData = (formData: FormData): SessionInput => {
  return {
    name: formData.name,
    event_id: formData.event_id,
    type: formData.type,
    number: stringToNumber(formData.number),
    scheduled_start: formData.scheduled_start || null,
    scheduled_end: formData.scheduled_end || null,
    actual_start: formData.actual_start || null,
    actual_end: formData.actual_end || null,
    podium_award_ceremony_url: formData.podium_award_ceremony_url || null,
  };
};
