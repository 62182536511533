import React from 'react';
import { Col, Row } from 'react-bootstrap';

import SelectField from 'components/FormFields/SelectField';
import { Award, Driver, Session } from 'types';
import { fieldsConfig } from '../driverAwardFormConfig';

interface Props {
  fieldsConfig: typeof fieldsConfig;
  sessions: Session[];
  awards: Award[];
  drivers: Driver[];
  disabled: boolean;
}

function DriverAwardFormFields(props: Props) {
  return (
    <>
      <Row>
        <Col xs={12} md={4}>
          <SelectField
            config={props.fieldsConfig.session_id}
            options={props.sessions.map(s => ({ label: s.name, value: s.id }))}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <SelectField
            config={props.fieldsConfig.award_id}
            options={props.awards.map(a => ({ label: a.name, value: a.id }))}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <SelectField
            config={props.fieldsConfig.driver_id}
            options={props.drivers.map(d => ({ label: `${d.first_name} ${d.last_name}`, value: d.id }))}
            disabled={props.disabled}
          />
        </Col>
      </Row>
    </>
  );
};

DriverAwardFormFields.defaultProps = {
  disabled: false,
};

export default DriverAwardFormFields;
