import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface Props {
  submitHandler: any;
  isLoading: boolean;
}

export default function FormButtons(props: Props) {
  const navigate = useNavigate();

  return (
    <div className="mb-3">
      <Button
        variant="primary"
        disabled={props.isLoading}
        onClick={props.submitHandler}
      >
        {(props.isLoading) ? 'Saving...' : 'Save'}
      </Button>

      <Button
        className="ms-3"
        variant="outline-secondary"
        disabled={props.isLoading}
        onClick={() => navigate(-1)}
      >
        Cancel
      </Button>
    </div>
  );
};
