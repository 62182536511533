import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

import type {
  Team,
  TeamInput,
  TeamMergeInput,
  TeamSearchQuery,
  TeamSearchResult,
} from 'types';
import { BASE_URL } from 'config';

export const teamsApi = createApi({
  reducerPath: 'teamsApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Team'],
  endpoints: (build) => ({
    getTeams: build.query<TeamSearchResult, TeamSearchQuery>({
      query: (queryParams) => `teams?${qs.stringify(queryParams)}`,
      providesTags: (result) =>
        result!.data
          ? [
            ...result!.data.map(({ id }) => ({ type: 'Team' as const, id })),
            { type: 'Team', id: 'LIST' },
          ]
          : [{ type: 'Team', id: 'LIST' }],
    }),
    getTeamById: build.query<Team, string>({
      query: (id) => `teams/${id}`,
      providesTags: (result, error, id) => [{ type: 'Team', id }],
    }),
    createTeam: build.mutation<Team, TeamInput>({
      query: (body) => {
        return {
          url: 'teams',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'Team', id: 'LIST' }],
    }),
    updateTeam: build.mutation<Team, TeamInput>({
      query: (body) => ({
        url: `teams/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Team', id }],
    }),
    deleteTeam: build.mutation<void, string>({
      query: (id) => ({
        url: `teams/${id}`,
        method: 'DELETE',
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Team', id }],
    }),
    mergeTeam: build.mutation<void, TeamMergeInput>({
      query: (body) => ({
        url: 'merge/team',
        method: 'POST',
        body,
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: [{ type: 'Team', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetTeamsQuery,
  useGetTeamByIdQuery,
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  useMergeTeamMutation,
} = teamsApi;
