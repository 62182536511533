import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';

import { useGetDriversQuery, useMergeDriverMutation } from 'reducers/drivers';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';
import SelectField from 'components/FormFields/SelectField';
import format from 'helpers/format';
import { DriverMergeInput } from 'types';

interface FormData {
  driverId: string;
  driverIdToMerge: string;
};

const defaultValues = {
  driverId: '',
  driverIdToMerge: '',
};

export default function DriverMerge() {
  const navigate = useNavigate();
  const { driverId: driverIdToMerge } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getDriversLoading,
    error: getDriversError,
    data: driversData,
  } = useGetDriversQuery({});

  const [
    mergeDriver,
    {
      isLoading: mergeDriverLoading,
      isSuccess: mergeDriverSuccess,
      error: mergeDriverError,
    },
  ] = useMergeDriverMutation();

  React.useEffect(() => {
    form.setValue('driverIdToMerge', driverIdToMerge!);
  }, [driverIdToMerge, form]);

  // Handle successful form submission
  React.useEffect(() => {
    if (mergeDriverSuccess) navigate(-1);
  }, [mergeDriverSuccess, navigate]);

  const onSubmit = async (formData: FormData) => {
    const payload = format.objToSnakeCase<DriverMergeInput>(formData);
    await mergeDriver(payload);
  };

  if (
    getDriversLoading ||
    mergeDriverLoading
  ) return <LoadingIndicator />;

  if (
    getDriversError ||
    mergeDriverError
  ) throw (
    getDriversError ||
    mergeDriverError
  );

  return (
    <>
      <PageHeader title="Merge Driver" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>

          <Row>
            <Col xs={12} md={4}>
              <SelectField
                config={{ name: 'driverIdToMerge', label: 'Merge this driver...' }}
                options={driversData!.data.map(d => ({ label: `${d.first_name} ${d.last_name}`, value: d.id }))}
                disabled
              />
            </Col>
            <Col xs={12} md={4}>
              <SelectField
                config={{ name: 'driverId', label: '...with this driver' }}
                options={
                  driversData!.data
                    .filter(d => d.id !== driverIdToMerge)
                    .map(d => ({ label: `${d.first_name} ${d.last_name}`, value: d.id }))
                }
              />
            </Col>
          </Row>

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={mergeDriverLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
