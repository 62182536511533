import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Form, Tab } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
} from 'features/DataSource/dataSourceFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import DeleteConfirmationButton from 'components/DeleteConfirmationButton';
import { useDeleteDataSourceMutation, useGetDataSourceByIdQuery } from 'reducers/data-sources';
import DataSourceFormFields from '../DataSourceFormFields';
import SeriesDataSourceList from 'features/SeriesDataSource/SeriesDataSourceList';
import PersistentTabs from 'components/PersistentTabs';

export default function DataSourceDetail() {
  const navigate = useNavigate();
  const { dataSourceId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getDataSourceLoading,
    error: getDataSourceError,
    data: dataSourceData,
  } = useGetDataSourceByIdQuery(dataSourceId!);

  const [
    deleteDataSource,
    {
      isLoading: deleteDataSourceLoading,
      error: deleteDataSourceError,
      isSuccess: deleteDataSourceSuccess,
    },
  ] = useDeleteDataSourceMutation();

  useInitializeFormFieldValues(dataSourceData, form);

  React.useEffect(() => {
    if (deleteDataSourceSuccess) navigate(-1);
  }, [deleteDataSourceSuccess, navigate]);

  if (
    getDataSourceLoading ||
    deleteDataSourceLoading
  ) return <LoadingIndicator />;

  if (
    getDataSourceError ||
    deleteDataSourceError
  ) throw (
    getDataSourceError ||
    deleteDataSourceError
  );

  return (
    <>
      <PageHeader title="Data Source">
        <Button onClick={() => navigate(`/data-sources/${dataSourceId}/edit`)}>Edit Data Source</Button>
        <DeleteConfirmationButton buttonText="Delete Data Source" action={() => deleteDataSource(dataSourceId!)} />
      </PageHeader>

      <PersistentTabs
        defaultActiveKey="details"
        className="mb-3"
      >
        <Tab eventKey="details" title="Details">
          <FormProvider {...form}>
            <Form>
              <DataSourceFormFields
                fieldsConfig={fieldsConfig}
                disabled
              />
            </Form>
          </FormProvider>
        </Tab>

        <Tab eventKey="series" title="Series">
          <SeriesDataSourceList showDataSourceColumn={false} />
        </Tab>
      </PersistentTabs>
    </>
  );
}
