import { Col, Row } from 'react-bootstrap';

import CheckboxField from 'components/FormFields/CheckboxField';
import SelectField from 'components/FormFields/SelectField';
import { DataSource } from 'types';
import { fieldsConfig } from '../eventDataSourceFormConfig';

interface Props {
  fieldsConfig: typeof fieldsConfig;
  dataSources: DataSource[];
  disabled: boolean;
}

function EventDataSourceFormFields(props: Props) {
  return (
    <Row>
      <Col xs={12} lg={6}>
        <SelectField
          config={props.fieldsConfig.id}
          options={props.dataSources.map(t => ({ label: t.source, value: t.id }))}
          disabled={props.disabled}
        />
      </Col>

      <Col xs={12} lg={6}>
        <CheckboxField
          config={props.fieldsConfig.is_primary}
          disabled={props.disabled}
        />
      </Col>
    </Row>
  )
}

EventDataSourceFormFields.defaultProps = {
  disabled: false,
};

export default EventDataSourceFormFields;