import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { skipToken } from '@reduxjs/toolkit/query/react'

import { useGetTeamsQuery } from 'reducers/teams';
import { useGetDriversQuery } from 'reducers/drivers';
import { useGetSessionByIdQuery, useGetSessionsQuery } from 'reducers/sessions';
import { useCreateSessionResultMutation } from 'reducers/session-results';
import SessionResultFormFields from 'features/SessionResult/SessionResultFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  formatPayloadFromFormData,
} from 'features/SessionResult/sessionResultFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function SessionResultNew() {
  const { seriesId, eventId, sessionId } = useParams();

  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getSessionLoading,
    error: getSessionError,
    data: sessionData,
  } = useGetSessionByIdQuery(sessionId!);

  const {
    isLoading: getSessionsLoading,
    error: getSessionsError,
    data: sessionsData,
  } = useGetSessionsQuery(sessionData?.event_id ? { event_id: sessionData?.event_id } : skipToken);

  const {
    isLoading: getTeamsLoading,
    error: getTeamsError,
    data: teamsData,
  } = useGetTeamsQuery({ series_id: seriesId });

  const {
    isLoading: getDriversLoading,
    error: getDriversError,
    data: driversData,
  } = useGetDriversQuery({});

  const [
    createSessionResult,
    {
      isLoading: createSessionResultLoading,
      isSuccess: createSessionResultSuccess,
      error: createSessionResultError,
    },
  ] = useCreateSessionResultMutation();

  React.useEffect(() => {
    form.setValue('session_id', sessionId!);
  }, [form, sessionId]);

  React.useEffect(() => {
    if (createSessionResultSuccess) navigate(-1);
  }, [createSessionResultSuccess, eventId, navigate, seriesId, sessionId]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    await createSessionResult(payload);
  };

  if (
    getSessionLoading ||
    getSessionsLoading ||
    getTeamsLoading ||
    getDriversLoading ||
    createSessionResultLoading
  ) return <LoadingIndicator />;

  if (
    getSessionError ||
    getSessionsError ||
    getTeamsError ||
    getDriversError ||
    createSessionResultError
  ) throw (
    getSessionError ||
    getSessionsError ||
    getTeamsError ||
    getDriversError ||
    createSessionResultError
  );

  return (
    <>
      <PageHeader title="Add Result" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <SessionResultFormFields
            fieldsConfig={fieldsConfig}
            sessions={sessionsData!.data}
            teams={teamsData!.data}
            drivers={driversData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={createSessionResultLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
