import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { skipToken } from '@reduxjs/toolkit/query/react'

import { useGetTeamsQuery } from 'reducers/teams';
import { useGetDriversQuery } from 'reducers/drivers';
import { useGetSessionByIdQuery, useGetSessionsQuery } from 'reducers/sessions';
import { useGetSessionResultByIdQuery, useUpdateSessionResultMutation } from 'reducers/session-results';
import SessionResultFormFields from 'features/SessionResult/SessionResultFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
  formatPayloadFromFormData,
} from 'features/SessionResult/sessionResultFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function SessionResultEdit() {
  const navigate = useNavigate();
  const { seriesId, sessionResultId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: sessionResultLoading,
    error: sessionResultError,
    data: sessionResultData,
  } = useGetSessionResultByIdQuery(sessionResultId!);

  const {
    isLoading: getSessionLoading,
    error: getSessionError,
    data: sessionData,
  } = useGetSessionByIdQuery(sessionResultData?.session_id ?? skipToken);

  const {
    isLoading: getSessionsLoading,
    error: getSessionsError,
    data: sessionsData,
  } = useGetSessionsQuery(sessionData?.event_id ? { event_id: sessionData?.event_id } : skipToken);

  const {
    isLoading: getTeamsLoading,
    error: getTeamsError,
    data: teamsData,
  } = useGetTeamsQuery({ series_id: seriesId });

  const {
    isLoading: getDriversLoading,
    error: getDriversError,
    data: driversData,
  } = useGetDriversQuery({});

  const [
    updateSessionResult,
    {
      isLoading: updateSessionResultLoading,
      isSuccess: updateSessionResultSuccess,
      error: updateSessionResultError,
    },
  ] = useUpdateSessionResultMutation();

  useInitializeFormFieldValues(sessionResultData, form);

  // Handle successful form submission
  React.useEffect(() => {
    if (updateSessionResultSuccess) navigate(-1);
  }, [updateSessionResultSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    payload.id = sessionResultId;

    await updateSessionResult(payload);
  };

  if (
    sessionResultLoading ||
    getSessionLoading ||
    getSessionsLoading ||
    getTeamsLoading ||
    getDriversLoading ||
    updateSessionResultLoading
  ) return <LoadingIndicator />;

  if (
    sessionResultError ||
    getSessionError ||
    getSessionsError ||
    getTeamsError ||
    getDriversError ||
    updateSessionResultError
  ) throw (
    sessionResultError ||
    getSessionError ||
    getSessionsError ||
    getTeamsError ||
    getDriversError ||
    updateSessionResultError
  );

  return (
    <>
      <PageHeader title={`${sessionData!.name} Result`} />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <SessionResultFormFields
            fieldsConfig={fieldsConfig}
            sessions={sessionsData!.data}
            teams={teamsData!.data}
            drivers={driversData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={updateSessionResultLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
