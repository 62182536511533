import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';

import EventDataSourceFormFields from '../EventDataSourceFormFields';
import {
  FormData,
  defaultValues,
  fieldsConfig,
  useInitializeFormFieldValues,
} from '../eventDataSourceFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import { useGetDataSourcesQuery } from 'reducers/data-sources';
import { useGetEventByIdQuery, useUpdateEventMutation } from 'reducers/events';
import { EventInput } from 'types';
import DeleteConfirmationButton from 'components/DeleteConfirmationButton';

export default function EventDataSourceDetail() {
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });

  const { seriesId, eventId, eventDataSourceId } = useParams();

  const {
    isLoading: getEventLoading,
    error: getEventError,
    data: event,
  } = useGetEventByIdQuery(eventId!);

  const {
    isLoading: getDataSourcesLoading,
    error: getDataSourcesError,
    data: dataSources,
  } = useGetDataSourcesQuery()

  const eventDataSource = React.useMemo(() => {
    return event?.data_sources.find(ds => ds.id === eventDataSourceId);
  }, [event, eventDataSourceId]);

  const [
    updateEvent,
    {
      isLoading: updateEventLoading,
      error: updateEventError,
      isSuccess: updateEventSuccess,
    },
  ] = useUpdateEventMutation();

  React.useEffect(() => {
    if (updateEventSuccess) navigate(-1);
  }, [updateEventSuccess, navigate]);

  useInitializeFormFieldValues(eventDataSource, form);

  const onDelete = async () => {
    const eventInput = {
      ...event,
      data_sources: event!.data_sources.filter(ds => ds.id !== eventDataSourceId),
    }
    await updateEvent(eventInput as EventInput);
  };

  if (
    getEventLoading ||
    getDataSourcesLoading ||
    updateEventLoading
  ) return <LoadingIndicator />;
  if (
    getEventError ||
    getDataSourcesError ||
    updateEventError
  ) throw (getEventError || getDataSourcesError || updateEventError);

  return (
    <>
      <PageHeader title="Event Data Source">
        <Button
          onClick={() => navigate(`/series/${seriesId}/events/${eventId}/data-sources/${eventDataSource!.id}/edit`)}
        >
          Edit event data source
        </Button>
        <DeleteConfirmationButton
          buttonText="Delete event data source"
          action={onDelete}
        />
      </PageHeader>

      <FormProvider {...form}>
        <Form>
          <EventDataSourceFormFields
            fieldsConfig={fieldsConfig}
            dataSources={dataSources!.data}
            disabled
          />
        </Form>
      </FormProvider>
    </>
  );
}