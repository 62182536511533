const determineEnv = () => {
  const isLocal = /localhost/.test(window.location.hostname);
  const isDev = /^dev/.test(window.location.hostname);
  const isStaging = /^staging/.test(window.location.hostname);

  if (isLocal) return 'local';
  if (isDev) return 'dev';
  if (isStaging) return 'staging';
  return 'prod';
};

const getHost = () => {
  switch (determineEnv()) {
    case 'local': return 'http://localhost';
    case 'dev': return 'https://dev.veritas.app.apex-mp.com';
    case 'staging': return 'https://staging.veritas.app.apex-mp.com';
    default: return 'https://veritas.app.apex-mp.com';
  }
};

export const API_PREFIX = 'api/v1';
export const ENV = determineEnv();
export const HOST = getHost();
export const basename = (ENV === 'local') ? '/apex-veritas' : '';
export const BASE_URL = `${HOST}/${API_PREFIX}`;
export const SIZE_PER_PAGE = 30;
