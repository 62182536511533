import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';

import { useGetSeriesQuery } from 'reducers/series';
import { useGetAwardsQuery } from 'reducers/awards';
import SectionHeader from 'components/SectionHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import BootstrapTable from 'components/BootstrapTable';

interface Props {
  disabledColumns: string[];
}

function AwardList(props: Props) {
  const navigate = useNavigate();
  const { seriesId } = useParams();

  const {
    isLoading: seriesLoading,
    error: seriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const {
    isLoading: awardsLoading,
    error: awardsError,
    data: awardsData,
  } = useGetAwardsQuery({ series_id: seriesId });

  if (
    seriesLoading ||
    awardsLoading
  ) return <LoadingIndicator />;

  if (
    seriesError ||
    awardsError
  ) throw (
    seriesError ||
    awardsError
  );

  const columns = [
    {
      dataField: 'series.id',
      text: 'Series',
      sort: true,
      filter: selectFilter({
        options: _.map(seriesData!.data, series => ({
          value: series.id,
          label: series.abbreviation,
        })),
        defaultValue: seriesId,
        disabled: props.disabledColumns.includes('series.id'),
      }),
      formatter: (cell: any, row: any) => row.series.abbreviation,
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      filter: textFilter(),
    },
  ];

  const rows: any = _.chain(awardsData!.data)
    .map(award => ({
      _rowLink: `/series/${seriesId}/awards/${award.id}`,
      id: award.id,
      series: _.find(seriesData!.data, { id: award.series_id }),
      name: award.name,
    }))
    .orderBy([
      (item) => item.series,
      (item) => item.name,
    ])
    .value();

  const defaultSorted = [{ dataField: 'series.id', order: 'asc' }];

  return (
    <>
      <SectionHeader title="Awards">
        <Button size="sm" onClick={() => navigate(`/series/${seriesId}/awards/new`)}>Add award</Button>
      </SectionHeader>

      <BootstrapTable cols={columns} rows={rows} defaultSorted={defaultSorted} />
    </>
  );
}

AwardList.defaultProps = {
  disabledColumns: [],
};

export default AwardList;
