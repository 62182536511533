import { useEffect } from 'react';

import { Organization, OrganizationInput } from 'types';

export const fieldsConfig = {
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  logo_url: {
    name: 'logo_url',
    label: 'Logo URL',
  },
  verified: {
    name: 'verified',
    label: 'Verified',
  },
};

export interface FormData {
  name: string;
  logo_url: string;
  verified: string;
};

export const defaultValues = {
  name: '',
  logo_url: '',
  verified: '',
};

export const useInitializeFormFieldValues = (organization: Organization | undefined, form: any) => {
  useEffect(() => {
    if (!organization) return;

    form.setValue('name', organization.name);
    form.setValue('logo_url', organization.logo_url ?? '');
    form.setValue('verified', String(organization.verified));
  }, [organization, form]);
};

export const formatPayloadFromFormData = (formData: FormData): OrganizationInput => {
  return {
    name: formData.name,
    logo_url: formData.logo_url || null,
    verified: formData.verified === 'true',
  };
};
