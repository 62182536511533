import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetSeriesQuery } from 'reducers/series';
import { useCreateAwardMutation } from 'reducers/awards';
import AwardFormFields from 'features/Award/AwardFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  formatPayloadFromFormData,
} from 'features/Award/awardFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function AwardNew() {
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });
  const { seriesId } = useParams();

  const {
    isLoading: seriesLoading,
    error: seriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const [
    createAward,
    {
      isLoading: createAwardLoading,
      isSuccess: createAwardSuccess,
    },
  ] = useCreateAwardMutation();

  React.useEffect(() => {
    form.setValue('series_id', seriesId!);
  }, [form, seriesId]);

  React.useEffect(() => {
    if (createAwardSuccess) navigate(-1);
  }, [createAwardSuccess, navigate, seriesId]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    await createAward(payload);
  };

  if (seriesLoading) return <LoadingIndicator />;
  if (seriesError) throw seriesError;

  return (
    <>
      <PageHeader title="Add Award" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <AwardFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={createAwardLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
