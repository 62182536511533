import React from 'react';
import { Col, Row } from 'react-bootstrap';

import TextField from 'components/FormFields/TextField';
import SelectField from 'components/FormFields/SelectField';
import { Team, Driver, Session } from 'types';
import { fieldsConfig } from '../sessionResultFormConfig';

interface Props {
  fieldsConfig: typeof fieldsConfig;
  sessions: Session[];
  teams: Team[];
  drivers: Driver[];
  disabled: boolean;
}

function SessionResultFormFields(props: Props) {
  return (
    <>
      <Row>
        <Col xs={12} md={4}>
          <SelectField
            config={props.fieldsConfig.session_id}
            options={props.sessions.map(s => ({ label: s.name, value: s.id }))}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={2}>
          <SelectField
            config={props.fieldsConfig.team_id}
            options={props.teams.map(c => ({ label: c.number, value: c.id }))}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <SelectField
            config={props.fieldsConfig.driver_id}
            options={props.drivers.map(d => ({ label: `${d.first_name} ${d.last_name}`, value: d.id }))}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.start_position}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.position}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.overall_rank}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.points}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.fastest_lap}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.fastest_lap_time}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.overall_best_lap_time}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.last_lap_time}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.laps_led}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.last_pitted_lap}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.laps_behind_prec}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.time_behind_prec}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.laps_behind_leader}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.time_behind_leader}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <SelectField
            config={props.fieldsConfig.did_not_finish}
            options={[
              { label: 'True', value: 'true' },
              { label: 'False', value: 'false' },
            ]}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.elapsed_time}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.pit_stop_count}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={3}>
          <TextField
            config={props.fieldsConfig.laps_completed}
            disabled={props.disabled}
          />
        </Col>
      </Row>
    </>
  );
};

SessionResultFormFields.defaultProps = {
  disabled: false,
};

export default SessionResultFormFields;
