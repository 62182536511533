import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import { textFilter } from 'react-bootstrap-table2-filter';
import _ from 'lodash';

import LoadingIndicator from 'components/LoadingIndicator';
import SectionHeader from 'components/SectionHeader';
import BootstrapTable from 'components/BootstrapTable';
import { useGetSeriesDataSourcesQuery } from 'reducers/series-data-sources';
import { SeriesDataSource } from 'types';

interface Props {
  showSeriesColumn?: boolean;
  showDataSourceColumn?: boolean;
}

function SeriesDataSourceList(props: Props) {
  const navigate = useNavigate();
  const { dataSourceId, seriesId } = useParams();

  const {
    isLoading: seriesDataSourcesLoading,
    error: seriesDataSourcesError,
    data: seriesDataSourcesData,
  } = useGetSeriesDataSourcesQuery({
    data_source_id: dataSourceId,
    series_id: seriesId,
  });

  if (seriesDataSourcesLoading) {
    return <LoadingIndicator />;
  }

  if (seriesDataSourcesError) {
    throw seriesDataSourcesError;
  }

  const cols = [
    {
      dataField: 'series',
      text: 'Series',
      filter: textFilter(),
      sort: true,
      hidden: !props.showSeriesColumn,
    },
    {
      dataField: 'data_source',
      text: 'Data Source',
      filter: textFilter(),
      sort: true,
      hidden: !props.showDataSourceColumn,
    },
    {
      dataField: 'is_primary',
      text: 'Primary',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'is_test',
      text: 'Test',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'year',
      text: 'Year',
      filter: textFilter(),
      sort: true,
    },
  ];

  const rows = _.chain(seriesDataSourcesData!.data)
    .map((s: SeriesDataSource) => {
      return {
        _rowLink: `/series/${s.series.id}/data-sources/${s.id}`,
        series: `${s.series.abbreviation} (${s.series.letter})`,
        data_source: s.data_source.source,
        is_primary: <Form.Check checked={s.is_primary} disabled />,
        is_test: <Form.Check checked={s.is_test} disabled />,
        year: s.year,
      };
    })
    .orderBy([item => item.year])
    .value();

  const defaultSorted = [{ dataField: 'year', order: 'asc' }];

  return (
    <>
      <p>
        Series data sources are used to define the <strong>default</strong> data
        sources that are linked to an event when one is created. Changing
        these has no effect on data sources already linked to existing events.
      </p>

      <SectionHeader title="Series Data Sources">
        {dataSourceId && <Button size="sm" onClick={() => navigate(`/data-sources/${dataSourceId}/series/new`)}>Add Series</Button>}
        {seriesId && <Button size="sm" onClick={() => navigate(`/series/${seriesId}/data-sources/new`)}>Add Data Source</Button>}
      </SectionHeader>

      <BootstrapTable
        cols={cols}
        rows={rows}
        defaultSorted={defaultSorted}
      />
    </>
  );
};

SeriesDataSourceList.defaultProps = {
  showSeriesColumn: true,
  showDataSourceColumn: true,
};

export default SeriesDataSourceList;