import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetSeriesQuery } from 'reducers/series';
import { useCreateSeriesMutation } from 'reducers/series';
import SeriesFormFields from 'features/Series/SeriesFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  formatPayloadFromFormData,
} from 'features/Series/seriesFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function SeriesNew() {
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: seriesLoading,
    error: seriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const [
    createSeries,
    {
      isLoading: createSeriesLoading,
      isSuccess: createSeriesSuccess,
    },
  ] = useCreateSeriesMutation();

  React.useEffect(() => {
    if (createSeriesSuccess) navigate(-1);
  }, [createSeriesSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    await createSeries(payload);
  };

  if (seriesLoading) return <LoadingIndicator />;
  if (seriesError) throw seriesError;

  return (
    <>
      <PageHeader title="Add Series" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <SeriesFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={createSeriesLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
