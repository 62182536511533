import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Accordion, Form, Image } from 'react-bootstrap';
import _ from 'lodash';

import { showValidationMessage } from 'components/FormFields/helpers';

interface FieldConfig {
  name: string;
  label?: string;
  validation?: any;
  validationMessages?: any;
}

interface Props {
  config: FieldConfig;
  disabled?: boolean;
}

// TODO: Implement upload functionality.
// This currently relies on the user providing a URL to the already uploaded image.
function ImageUpload(props: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.config.name}
      control={control}
      rules={props.config.validation}
      render={({ field, formState }) => {
        return (
          <Form.Group className="mb-3">
            <Form.Label>{props.config.label}</Form.Label>
            <Form.Control
              {...field}
              isInvalid={!_.isEmpty(formState.errors[props.config.name])}
              disabled={props.disabled}
            />
            {showValidationMessage(formState, props.config.name, props.config.validationMessages)}

            <Accordion className="mt-2">
              <Accordion.Item eventKey="0">
                <Accordion.Header>View image</Accordion.Header>
                <Accordion.Body>
                  <Image src={field.value} width="100%" />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Form.Group>
        );
      }}
    />
  );
};

ImageUpload.defaultProps = {
  disabled: false,
};

export default ImageUpload;
