import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetTeamByIdQuery, useDeleteTeamMutation } from 'reducers/teams';
import { useGetSeriesQuery } from 'reducers/series';
import TeamFormFields from 'features/Team/TeamFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
} from 'features/Team/teamFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import DeleteConfirmationButton from 'components/DeleteConfirmationButton';
import { useGetOrganizationsQuery } from 'reducers/organizations';

export default function TeamDetail() {
  const navigate = useNavigate();
  const { seriesId, teamId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getTeamLoading,
    error: getTeamError,
    data: teamData,
  } = useGetTeamByIdQuery(teamId!);

  const {
    isLoading: getSeriesLoading,
    error: getSeriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const {
    isLoading: getOrganizationsLoading,
    error: getOrganizationsError,
    data: organizationsData,
  } = useGetOrganizationsQuery({});
  
  const [
    deleteTeam,
    {
      isLoading: deleteTeamLoading,
      error: deleteTeamError,
      isSuccess: deleteTeamSuccess,
    },
  ] = useDeleteTeamMutation();

  useInitializeFormFieldValues(teamData, form);

  React.useEffect(() => {
    if (deleteTeamSuccess) navigate(-1);
  }, [deleteTeamSuccess, navigate, seriesId]);

  if (getTeamLoading ||
    getSeriesLoading ||
    deleteTeamLoading ||
    getOrganizationsLoading
  ) return <LoadingIndicator />;

  if (getTeamError ||
    getSeriesError ||
    deleteTeamError ||
    getOrganizationsError
  ) throw (
    getTeamError ||
    getSeriesError ||
    deleteTeamError ||
    getOrganizationsError
  );

  return (
    <>
      <PageHeader title={teamData!.number}>
        <Button onClick={() => navigate(`/series/${seriesId}/teams/${teamId}/merge`)}>Merge team</Button>
        <Button onClick={() => navigate(`/series/${seriesId}/teams/${teamId}/edit`)}>Edit team</Button>
        <DeleteConfirmationButton buttonText="Delete team" action={() => deleteTeam(teamId!)} />
      </PageHeader>

      <FormProvider {...form}>
        <Form>
          <TeamFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
            disabled
            organizations={organizationsData!.data}
          />
        </Form>
      </FormProvider>
    </>
  );
}
