import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';

import { useGetOrganizationsQuery, useMergeOrganizationMutation } from 'reducers/organizations';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';
import SelectField from 'components/FormFields/SelectField';
import format from 'helpers/format';
import { OrganizationMergeInput } from 'types';

interface FormData {
  organizationId: string;
  organizationIdToMerge: string;
};

const defaultValues = {
  organizationId: '',
  organizationIdToMerge: '',
};

export default function OrganizationMerge() {
  const navigate = useNavigate();
  const { organizationId: organizationIdToMerge } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getOrganizationsLoading,
    error: getOrganizationsError,
    data: organizationsData,
  } = useGetOrganizationsQuery({});

  const [
    mergeOrganization,
    {
      isLoading: mergeOrganizationLoading,
      isSuccess: mergeOrganizationSuccess,
      error: mergeOrganizationError,
    },
  ] = useMergeOrganizationMutation();

  React.useEffect(() => {
    form.setValue('organizationIdToMerge', organizationIdToMerge!);
  }, [organizationIdToMerge, form]);

  // Handle successful form submission
  React.useEffect(() => {
    if (mergeOrganizationSuccess) navigate(-1);
  }, [mergeOrganizationSuccess, navigate]);

  const onSubmit = async (formData: FormData) => {
    const payload = format.objToSnakeCase<OrganizationMergeInput>(formData);
    await mergeOrganization(payload);
  };

  if (
    getOrganizationsLoading ||
    mergeOrganizationLoading
  ) return <LoadingIndicator />;

  if (
    getOrganizationsError ||
    mergeOrganizationError
  ) throw (
    getOrganizationsError ||
    mergeOrganizationError
  );

  return (
    <>
      <PageHeader title="Merge Organization" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>

          <Row>
            <Col xs={12} md={6}>
              <SelectField
                config={{ name: 'organizationIdToMerge', label: 'Merge this organization...' }}
                options={organizationsData!.data.map(t => ({ label: t.name, value: t.id }))}
                disabled
              />
            </Col>
            <Col xs={12} md={6}>
              <SelectField
                config={{ name: 'organizationId', label: '...with this organization' }}
                options={
                  organizationsData!.data
                    .filter(t => t.id !== organizationIdToMerge)
                    .map(t => ({ label: t.name, value: t.id }))
                }
              />
            </Col>
          </Row>

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={mergeOrganizationLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
