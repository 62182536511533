import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';

import { useGetTeamsQuery, useMergeTeamMutation } from 'reducers/teams';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';
import SelectField from 'components/FormFields/SelectField';
import format from 'helpers/format';
import { TeamMergeInput } from 'types';

interface FormData {
  teamId: string;
  teamIdToMerge: string;
};

const defaultValues = {
  teamId: '',
  teamIdToMerge: '',
};

export default function TeamMerge() {
  const navigate = useNavigate();
  const { seriesId, teamId: teamIdToMerge } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getTeamsLoading,
    error: getTeamsError,
    data: teamsData,
  } = useGetTeamsQuery({ series_id: seriesId });

  const [
    mergeTeam,
    {
      isLoading: mergeTeamLoading,
      isSuccess: mergeTeamSuccess,
      error: mergeTeamError,
    },
  ] = useMergeTeamMutation();

  React.useEffect(() => {
    form.setValue('teamIdToMerge', teamIdToMerge!);
  }, [teamIdToMerge, form]);

  // Handle successful form submission
  React.useEffect(() => {
    if (mergeTeamSuccess) navigate(-1);
  }, [mergeTeamSuccess, navigate, seriesId]);

  const onSubmit = async (formData: FormData) => {
    const payload = format.objToSnakeCase<TeamMergeInput>(formData);
    await mergeTeam(payload);
  };

  if (
    getTeamsLoading ||
    mergeTeamLoading
  ) return <LoadingIndicator />;

  if (
    getTeamsError ||
    mergeTeamError
  ) throw (
    getTeamsError ||
    mergeTeamError
  );

  return (
    <>
      <PageHeader title="Merge Team" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>

          <Row>
            <Col xs={12} md={4}>
              <SelectField
                config={{ name: 'teamIdToMerge', label: 'Merge this team...' }}
                options={teamsData!.data.map(c => ({ label: c.number, value: c.id }))}
                disabled
              />
            </Col>
            <Col xs={12} md={4}>
              <SelectField
                config={{ name: 'teamId', label: '...with this team' }}
                options={
                  teamsData!.data
                    .filter(c => c.id !== teamIdToMerge)
                    .map(c => ({ label: c.number, value: c.id }))
                }
              />
            </Col>
          </Row>

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={mergeTeamLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
