import React from 'react';
import { Col, Row } from 'react-bootstrap';

import SelectField from 'components/FormFields/SelectField';
import TextField from 'components/FormFields/TextField';
import { fieldsConfig } from '../trackFormConfig';

interface Props {
  fieldsConfig: typeof fieldsConfig;
  disabled: boolean;
}

function TrackFormFields(props: Props) {
  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <TextField
            config={props.fieldsConfig.long_name}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.short_name}
            disabled={props.disabled}
          />
        </Col>
        <Col xs={12} md={2}>
          <TextField
            config={props.fieldsConfig.mini_name}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4}>
          <SelectField
            config={props.fieldsConfig.timezone_id}
            options={[
              { value: 'America/Los_Angeles', label: 'America/Los_Angeles' },
              { value: 'America/Phoenix', label: 'America/Phoenix' },
              { value: 'America/Chicago', label: 'America/Chicago' },
              { value: 'America/New_York', label: 'America/New_York' },
            ]}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.city}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.state}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.elevation}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.latitude}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.longitude}
            disabled={props.disabled}
          />
        </Col>
      </Row>
    </>
  );
};

TrackFormFields.defaultProps = {
  disabled: false,
};

export default TrackFormFields;
