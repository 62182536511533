import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Form, Tab } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetDriverByIdQuery, useDeleteDriverMutation } from 'reducers/drivers';
import { useGetSeriesQuery } from 'reducers/series';
import DriverFormFields from 'features/Driver/DriverFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
} from 'features/Driver/driverFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import DeleteConfirmationButton from 'components/DeleteConfirmationButton';
import DriverAwardList from 'features/DriverAward/DriverAwardList';
import PersistentTabs from 'components/PersistentTabs';

export default function DriverDetail() {
  const navigate = useNavigate();
  const { driverId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getDriverLoading,
    error: getDriverError,
    data: driverData,
  } = useGetDriverByIdQuery(driverId!);

  const {
    isLoading: getSeriesLoading,
    error: getSeriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const [
    deleteDriver,
    {
      isLoading: deleteDriverLoading,
      error: deleteDriverError,
      isSuccess: deleteDriverSuccess,
    },
  ] = useDeleteDriverMutation();

  useInitializeFormFieldValues(driverData, form);

  React.useEffect(() => {
    if (deleteDriverSuccess) navigate(-1);
  }, [deleteDriverSuccess, navigate]);

  if (getDriverLoading ||
    getSeriesLoading ||
    deleteDriverLoading
  ) return <LoadingIndicator />;

  if (getDriverError ||
    getSeriesError ||
    deleteDriverError
  ) throw (
    getDriverError ||
    getSeriesError ||
    deleteDriverError
  );

  return (
    <>
      <PageHeader title={`${driverData!.first_name} ${driverData!.last_name}`}>
        <Button onClick={() => navigate(`/drivers/${driverId}/merge`)}>Merge driver</Button>
        <Button onClick={() => navigate(`/drivers/${driverId}/edit`)}>Edit driver</Button>
        <DeleteConfirmationButton buttonText="Delete driver" action={() => deleteDriver(driverId!)} />
      </PageHeader>

      <PersistentTabs
        defaultActiveKey="details"
        className="mb-3"
      >
        <Tab eventKey="details" title="Details">
          <FormProvider {...form}>
            <Form>
              <DriverFormFields
                fieldsConfig={fieldsConfig}
                series={seriesData!.data}
                disabled
              />
            </Form>
          </FormProvider>
        </Tab>

        <Tab eventKey="awards" title="Awards">
          <DriverAwardList title="Awards" driverId={driverId} disabledColumns={['driver.id']} />
        </Tab>
      </PersistentTabs>
    </>
  );
}
