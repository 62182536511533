import React from 'react';
import { useRouteLoaderData } from 'react-router-dom';

import styles from './index.module.css';

interface Props {
  title: string;
  children?: any;
}

export default function SectionHeader(props: Props) {
  const permissions = useRouteLoaderData('root') as string[];

  if (!permissions.includes('modify')) return null;

  return (
    <div className="d-flex justify-content-between align-items-center mt-5">
      <h3>{props.title}</h3>
      <div className={styles.actions}>
        {props.children}
      </div>
    </div>
  );
}
