import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Form, Tab } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetAwardByIdQuery, useDeleteAwardMutation } from 'reducers/awards';
import { useGetSeriesQuery } from 'reducers/series';
import AwardFormFields from 'features/Award/AwardFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
} from 'features/Award/awardFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import DeleteConfirmationButton from 'components/DeleteConfirmationButton';
import DriverAwardList from 'features/DriverAward/DriverAwardList';
import PersistentTabs from 'components/PersistentTabs';

export default function AwardDetail() {
  const navigate = useNavigate();
  const { seriesId, awardId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getAwardLoading,
    error: getAwardError,
    data: awardData,
  } = useGetAwardByIdQuery(awardId!);

  const {
    isLoading: getSeriesLoading,
    error: getSeriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const [
    deleteAward,
    {
      isLoading: deleteAwardLoading,
      error: deleteAwardError,
      isSuccess: deleteAwardSuccess,
    },
  ] = useDeleteAwardMutation();

  useInitializeFormFieldValues(awardData, form);

  React.useEffect(() => {
    if (deleteAwardSuccess) navigate(-1);
  }, [deleteAwardSuccess, navigate, seriesId]);

  if (getAwardLoading ||
    getSeriesLoading ||
    deleteAwardLoading
  ) return <LoadingIndicator />;

  if (getAwardError ||
    getSeriesError ||
    deleteAwardError
  ) throw (
    getAwardError ||
    getSeriesError ||
    deleteAwardError
  );

  return (
    <>
      <PageHeader title={awardData!.name}>
        <Button onClick={() => navigate(`/series/${seriesId}/awards/${awardId}/edit`)}>Edit award</Button>
        <DeleteConfirmationButton buttonText="Delete award" action={() => deleteAward(awardId!)} />
      </PageHeader>

      <PersistentTabs
        defaultActiveKey="details"
        className="mb-3"
      >
        <Tab eventKey="details" title="Details">
          <FormProvider {...form}>
            <Form>
              <AwardFormFields
                fieldsConfig={fieldsConfig}
                series={seriesData!.data}
                disabled
              />
            </Form>
          </FormProvider>
        </Tab>

        <Tab eventKey="recipients" title="Recipients">
          <DriverAwardList title="Recipients" awardId={awardId} />
        </Tab>
      </PersistentTabs>
    </>
  );
}
