import React from 'react';
import { Col, Row } from 'react-bootstrap';

import ImageUploadField from 'components/FormFields/ImageUploadField';
import TextField from 'components/FormFields/TextField';
import SelectField from 'components/FormFields/SelectField';
import { Series } from 'types';
import { fieldsConfig } from '../driverFormConfig';

interface Props {
  fieldsConfig: typeof fieldsConfig;
  series: Series[];
  disabled: boolean;
}

function DriverFormFields(props: Props) {
  return (
    <>
      <Row>
        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.first_name}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.last_name}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.email}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={2}>
          <TextField
            config={props.fieldsConfig.birthdate}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={2}>
          <SelectField
            config={props.fieldsConfig.gender}
            options={[
              { value: 'male', label: 'Male' },
              { value: 'female', label: 'Female' },
            ]}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={2}>
          <SelectField
            config={props.fieldsConfig.verified}
            options={[
              { value: 'true', label: 'Yes' },
              { value: 'false', label: 'No' },
            ]}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={6}>
          <ImageUploadField
            config={props.fieldsConfig.photo_url}
            disabled={props.disabled}
          />
        </Col>
      </Row>
    </>
  );
};

DriverFormFields.defaultProps = {
  disabled: false,
};

export default DriverFormFields;
