import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { selectFilter } from 'react-bootstrap-table2-filter';
import _ from 'lodash';

import { useGetAwardsQuery } from 'reducers/awards';
import { useGetDriverAwardsQuery } from 'reducers/driver-awards';
import { useGetDriversQuery } from 'reducers/drivers';
import { useGetSessionsQuery } from 'reducers/sessions';
import LoadingIndicator from 'components/LoadingIndicator';
import SectionHeader from 'components/SectionHeader';
import BootstrapTable from 'components/BootstrapTable';

interface Props {
  title: string;
  awardId?: string;
  driverId?: string;
  sessionId?: string;
  disabledColumns: string[];
}

function DriverAwardList(props: Props) {
  const navigate = useNavigate();

  const {
    isLoading: getDriverAwardsLoading,
    error: getDriverAwardsError,
    data: driverAwardsData,
  } = useGetDriverAwardsQuery({ award_id: props.awardId });

  const {
    isLoading: getAwardsLoading,
    error: getAwardsError,
    data: awardsData,
  } = useGetAwardsQuery({});

  const {
    isLoading: getDriversLoading,
    error: getDriversError,
    data: driversData,
  } = useGetDriversQuery({});

  const {
    isLoading: getSessionsLoading,
    error: getSessionsError,
    data: sessionsData,
  } = useGetSessionsQuery({});

  if (
    getDriverAwardsLoading ||
    getAwardsLoading ||
    getDriversLoading ||
    getSessionsLoading
  ) return <LoadingIndicator />;

  if (
    getDriverAwardsError ||
    getAwardsError ||
    getDriversError ||
    getSessionsError
  ) throw (
    getDriverAwardsError ||
    getAwardsError ||
    getDriversError ||
    getSessionsError
  );

  const columns = [
    {
      dataField: 'award.id',
      text: 'Award',
      sort: true,
      filter: selectFilter({
        options: _.map(awardsData!.data, award => ({
          value: award.id,
          label: award.name,
        })),
        defaultValue: props.awardId,
        disabled: props.disabledColumns.includes('award.id'),
      }),
      formatter: (cell: any, row: any) => row.award.name,
    },
    {
      dataField: 'session.id',
      text: 'Session',
      sort: true,
      filter: selectFilter({
        options: _.map(sessionsData!.data, session => ({
          value: session.id,
          label: session.name,
        })),
        defaultValue: props.sessionId,
        disabled: props.disabledColumns.includes('session.id'),
      }),
      formatter: (cell: any, row: any) => row.session.name,
    },
    {
      dataField: 'driver.id',
      text: 'Driver',
      sort: true,
      filter: selectFilter({
        options: _.map(driversData!.data, driver => ({
          value: driver.id,
          label: `${driver.first_name} ${driver.last_name}`,
        })),
        defaultValue: props.driverId,
        disabled: props.disabledColumns.includes('driver.id'),
      }),
      formatter: (cell: any, row: any) => `${row.driver.first_name} ${row.driver.last_name}`,
    },
  ];

  const rows: any = _.chain(driverAwardsData!.data)
    .map(driverAward => ({
      _rowLink: `/driver-awards/${driverAward.id}`,
      id: driverAward.id,
      award: _.find(awardsData!.data, { id: driverAward.award_id })!,
      driver: _.find(driversData!.data, { id: driverAward.driver_id }),
      session: _.find(sessionsData!.data, { id: driverAward.session_id }),
    }))
    .orderBy([
      (item) => item.session,
    ])
    .value();

  const defaultSorted = [{ dataField: 'session', order: 'asc' }];

  return (
    <>
      <SectionHeader title={props.title}>
        <Button size="sm" onClick={() => navigate('/driver-awards/new')}>Add driver award</Button>
      </SectionHeader>

      <BootstrapTable
        cols={columns}
        rows={rows}
        defaultSorted={defaultSorted}
      />
    </>
  );
};

DriverAwardList.defaultProps = {
  disabledColumns: [],
};

export default DriverAwardList;
