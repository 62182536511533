export default function msToTime(duration: number) {
  if (duration === null) return '';

  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const seconds = Math.floor((duration / 1000) % 60);
  const milliseconds = duration % 1000;

  const hoursPadded = String(hours).padStart(2, '0');
  const minutesPadded = String(minutes).padStart(2, '0');
  const secondsPadded = String(seconds).padStart(2, '0');
  const millisecondsPadded = String(milliseconds).padStart(3, '0');

  return `${hoursPadded}:${minutesPadded}:${secondsPadded}.${millisecondsPadded}`;
}
