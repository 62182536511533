import React from 'react';
import Form from 'react-bootstrap/Form';

interface Props {
  message: string;
}

export default function ValidationMessage(props: Props) {
  return (
    <Form.Control.Feedback type="invalid">
      {props.message}
    </Form.Control.Feedback>
  );
};
