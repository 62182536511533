import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useCreateEventMutation } from 'reducers/events';
import { useGetSeriesQuery } from 'reducers/series';
import { useGetTrackConfigurationsQuery } from 'reducers/track-configurations';
import { useGetTracksQuery } from 'reducers/tracks';
import EventFormFields from 'features/Event/EventFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  formatPayloadFromFormData,
} from 'features/Event/eventFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function EventDetail() {
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: seriesLoading,
    error: seriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const {
    isLoading: tracksLoading,
    error: tracksError,
    data: tracksData,
  } = useGetTracksQuery({});

  const {
    isLoading: trackConfigurationsLoading,
    error: trackConfigurationsError,
    data: trackConfigurationsData,
  } = useGetTrackConfigurationsQuery({});

  const [
    createEvent,
    {
      isLoading: createEventLoading,
      isSuccess: createEventSuccess,
      error: createEventError,
    },
  ] = useCreateEventMutation();

  // Handle successful form submission
  React.useEffect(() => {
    if (createEventSuccess) navigate(-1);
  }, [createEventSuccess, navigate]);

  const onSubmit = async (formData: FormData) => {
    const track = tracksData!.data.find(t => t.id === formData.track_id);
    const payload = formatPayloadFromFormData(formData, track!.timezone_id);
    await createEvent(payload);
  };

  if (
    seriesLoading ||
    createEventLoading ||
    trackConfigurationsLoading ||
    tracksLoading
  ) return <LoadingIndicator />;

  if (
    seriesError ||
    createEventError ||
    trackConfigurationsError ||
    tracksError
  ) throw (
    seriesError ||
    createEventError ||
    trackConfigurationsError ||
    tracksError
  );

  return (
    <>
      <PageHeader title="Add Event" />

      <FormProvider {...form}>
        <Form>
          <EventFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
            tracks={tracksData!.data}
            trackConfigurations={trackConfigurationsData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={createEventLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
}
