import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import styles from './index.module.css';

export default function LoadingIndicator() {
  return (
    <div className={styles.container}>
      <Spinner animation="border" role="status" className={styles.spinner}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}
