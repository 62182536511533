import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetEventsQuery } from 'reducers/events';
import { useGetSessionByIdQuery, useUpdateSessionMutation } from 'reducers/sessions';
import SessionFormFields from 'features/Session/SessionFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
  formatPayloadFromFormData,
} from 'features/Session/sessionFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function SessionEdit() {
  const navigate = useNavigate();
  const { seriesId, sessionId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getEventsLoading,
    error: getEventsError,
    data: eventsData,
  } = useGetEventsQuery({ series_id: seriesId });

  const {
    isLoading: getSessionLoading,
    error: getSessionError,
    data: session,
  } = useGetSessionByIdQuery(sessionId!);

  const [
    updateSession,
    {
      isLoading: updateSessionLoading,
      isSuccess: updateSessionSuccess,
      error: updateSessionError,
    },
  ] = useUpdateSessionMutation();

  useInitializeFormFieldValues(session, form);

  // Handle successful form submission
  React.useEffect(() => {
    if (updateSessionSuccess) navigate(-1);
  }, [updateSessionSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    payload.id = sessionId;

    await updateSession(payload);
  };

  if (
    getEventsLoading ||
    getSessionLoading ||
    updateSessionLoading
  ) return <LoadingIndicator />;

  if (
    getEventsError ||
    getSessionError ||
    updateSessionError
  ) throw (
    getEventsError ||
    getSessionError ||
    updateSessionError
  );

  return (
    <>
      <PageHeader title="Edit Session" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <SessionFormFields
            fieldsConfig={fieldsConfig}
            events={eventsData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={updateSessionLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
