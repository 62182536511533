import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { dateFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { format } from 'date-fns';

import { useGetSeriesQuery } from 'reducers/series';
import { useGetEventsQuery } from 'reducers/events';
import PageHeader from 'components/PageHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import BootstrapTable from 'components/BootstrapTable';

interface Props {
  disabledColumns: string[];
}

function EventList(props: Props) {
  const navigate = useNavigate();
  const { seriesId } = useParams();

  const {
    isLoading: seriesLoading,
    error: seriesError,
    data: seriesData,
  } = useGetSeriesQuery();
  const {
    isLoading: eventsLoading,
    error: eventsError,
    data: eventsData,
  } = useGetEventsQuery({});

  if (
    seriesLoading ||
    eventsLoading
  ) return <LoadingIndicator />;

  if (
    seriesError ||
    eventsError
  ) throw (
    seriesError ||
    eventsError
  );

  const columns = [
    {
      dataField: 'series.id',
      text: 'Series',
      sort: true,
      filter: selectFilter({
        options: _.map(seriesData!.data, series => ({
          value: series.id,
          label: series.abbreviation,
        })),
        defaultValue: seriesId,
        disabled: props.disabledColumns.includes('series.id'),
      }),
      formatter: (cell: any, row: any) => row.series.abbreviation,
      headerStyle: {
        width: 150,
      },
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'event_start',
      text: 'Start',
      sort: true,
      filter: dateFilter(),
      formatter: (cell: string | null) => cell && format(new Date(cell), 'M/d/yyyy'),
      headerStyle: {
        width: 225,
      },
    },
    {
      dataField: 'event_end',
      text: 'End',
      sort: true,
      filter: dateFilter(),
      formatter: (cell: string | null) => cell && format(new Date(cell), 'M/d/yyyy'),
      headerStyle: {
        width: 225,
      },
    },
    {
      dataField: 'is_test',
      text: 'Test',
      sort: true,
      filter: selectFilter({
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ],
      }),
      formatter: (isTest: boolean) => (isTest) ? 'Yes' : 'No',
      headerStyle: {
        width: 150,
      },
    },
  ];

  const rows: any = _.chain(eventsData!.data)
    .map(event => ({
      _rowLink: `/series/${event.series_id}/events/${event.id}`,
      id: event.id,
      series: _.find(seriesData!.data, { id: event.series_id }),
      name: event.name,
      event_start: event.event_start,
      event_end: event.event_end,
      is_test: event.is_test,
    }))
    .orderBy([
      (item) => item.series,
      (item) => item.event_start,
    ])
    .value();

  const defaultSorted = [{ dataField: 'series', order: 'asc' }];

  return (
    <>
      <PageHeader title="Events">
        <Button onClick={() => navigate('/events/new')}>Add event</Button>
      </PageHeader>

      <BootstrapTable cols={columns} rows={rows} defaultSorted={defaultSorted} />
    </>
  );
}

EventList.defaultProps = {
  disabledColumns: [],
};

export default EventList;
