import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type {
  Series,
  SeriesInput,
  SeriesSearchResult,
} from 'types';
import { BASE_URL } from 'config';

export const seriesApi = createApi({
  reducerPath: 'seriesApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Series'],
  endpoints: (build) => ({
    getSeries: build.query<SeriesSearchResult, void>({
      query: () => 'series',
      providesTags: (result) =>
        result!.data
          ? [
            ...result!.data.map(({ id }) => ({ type: 'Series' as const, id })),
            { type: 'Series', id: 'LIST' },
          ]
          : [{ type: 'Series', id: 'LIST' }],
    }),
    getSeriesById: build.query<Series, string>({
      query: (id) => `series/${id}`,
      providesTags: (result, error, id) => [{ type: 'Series', id }],
    }),
    createSeries: build.mutation<Series, SeriesInput>({
      query: (body) => {
        return {
          url: 'series',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'Series', id: 'LIST' }],
    }),
    updateSeries: build.mutation<Series, SeriesInput>({
      query: (body) => ({
        url: `series/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Series', id }],
    }),
    deleteSeries: build.mutation<void, string>({
      query: (id) => ({
        url: `series/${id}`,
        method: 'DELETE',
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Series', id }],
    }),
  }),
});

export const {
  useGetSeriesQuery,
  useGetSeriesByIdQuery,
  useCreateSeriesMutation,
  useUpdateSeriesMutation,
  useDeleteSeriesMutation,
} = seriesApi;
