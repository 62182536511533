import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

import type {
  TrackConfiguration,
  TrackConfigurationInput,
  TrackConfigurationSearchQuery,
  TrackConfigurationSearchResult,
} from 'types';
import { BASE_URL } from 'config';

export const trackConfigurationsApi = createApi({
  reducerPath: 'trackConfigurationsApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['TrackConfiguration'],
  endpoints: (build) => ({
    getTrackConfigurations: build.query<TrackConfigurationSearchResult, TrackConfigurationSearchQuery>({
      query: (queryParams) => `track-configurations?${qs.stringify(queryParams)}`,
      providesTags: (result) =>
        result!.data
          ? [
            ...result!.data.map(({ id }) => ({ type: 'TrackConfiguration' as const, id })),
            { type: 'TrackConfiguration', id: 'LIST' },
          ]
          : [{ type: 'TrackConfiguration', id: 'LIST' }],
    }),
    getTrackConfigurationById: build.query<TrackConfiguration, string>({
      query: (id) => `track-configurations/${id}`,
      providesTags: (result, error, id) => [{ type: 'TrackConfiguration', id }],
    }),
    createTrackConfiguration: build.mutation<TrackConfiguration, TrackConfigurationInput>({
      query: (body) => {
        return {
          url: 'track-configurations',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'TrackConfiguration', id: 'LIST' }],
    }),
    updateTrackConfiguration: build.mutation<TrackConfiguration, TrackConfigurationInput>({
      query: (body) => ({
        url: `track-configurations/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'TrackConfiguration', id }],
    }),
    deleteTrackConfiguration: build.mutation<void, string>({
      query: (id) => ({
        url: `track-configurations/${id}`,
        method: 'DELETE',
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: (result, error, id) => [{ type: 'TrackConfiguration', id }],
    }),
  }),
});

export const {
  useGetTrackConfigurationsQuery,
  useGetTrackConfigurationByIdQuery,
  useCreateTrackConfigurationMutation,
  useUpdateTrackConfigurationMutation,
  useDeleteTrackConfigurationMutation,
} = trackConfigurationsApi;
