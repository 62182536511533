import React from 'react';
import { Col, Row } from 'react-bootstrap';

import TextField from 'components/FormFields/TextField';
import SelectField from 'components/FormFields/SelectField';
import { Series } from 'types';
import { fieldsConfig } from '../seriesAliasFormConfig';

interface Props {
  fieldsConfig: typeof fieldsConfig;
  series: Series[];
  disabled: boolean;
}

function SeriesAliasFormFields(props: Props) {
  return (
    <>
      <Row>
        <Col xs={12} md={4}>
          <SelectField
            config={props.fieldsConfig.series_id}
            options={props.series.map(s => ({ label: s.abbreviation, value: s.id }))}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.name}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} md={4}>
          <TextField
            config={props.fieldsConfig.year}
            disabled={props.disabled}
          />
        </Col>
      </Row>
    </>
  );
};

SeriesAliasFormFields.defaultProps = {
  disabled: false,
};

export default SeriesAliasFormFields;
