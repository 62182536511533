import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

import type {
  SeriesDataSource,
  SeriesDataSourceInput,
  SeriesDataSourceSearchQuery,
  SeriesDataSourceSearchResult,
} from 'types';
import { BASE_URL } from 'config';

export const seriesDataSourcesApi = createApi({
  reducerPath: 'seriesDataSourcesApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['SeriesDataSource'],
  endpoints: (build) => ({
    getSeriesDataSources: build.query<SeriesDataSourceSearchResult, SeriesDataSourceSearchQuery>({
      query: (queryParams) => `series-data-sources?${qs.stringify(queryParams)}`,
      providesTags: (result) =>
        result!.data
          ? [
            ...result!.data.map(({ id }) => ({ type: 'SeriesDataSource' as const, id })),
            { type: 'SeriesDataSource', id: 'LIST' },
          ]
          : [{ type: 'SeriesDataSource', id: 'LIST' }],
    }),
    getSeriesDataSourceById: build.query<SeriesDataSource, string>({
      query: (id) => `series-data-sources/${id}`,
      providesTags: (result, error, id) => [{ type: 'SeriesDataSource', id }],
    }),
    createSeriesDataSource: build.mutation<SeriesDataSource, SeriesDataSourceInput>({
      query: (body) => {
        return {
          url: 'series-data-sources',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'SeriesDataSource', id: 'LIST' }],
    }),
    updateSeriesDataSource: build.mutation<SeriesDataSource, SeriesDataSourceInput>({
      query: (body) => ({
        url: `series-data-sources/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'SeriesDataSource', id }],
    }),
    deleteSeriesDataSource: build.mutation<void, string>({
      query: (id) => ({
        url: `series-data-sources/${id}`,
        method: 'DELETE',
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: (result, error, id) => [{ type: 'SeriesDataSource', id }],
    }),
  }),
});

export const {
  useGetSeriesDataSourcesQuery,
  useGetSeriesDataSourceByIdQuery,
  useCreateSeriesDataSourceMutation,
  useUpdateSeriesDataSourceMutation,
  useDeleteSeriesDataSourceMutation,
} = seriesDataSourcesApi;
