import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

import type {
  SessionResult,
  SessionResultInput,
  SessionResultSearchQuery,
  SessionResultSearchResult,
} from 'types';
import { BASE_URL } from 'config';

export const sessionResultsApi = createApi({
  reducerPath: 'sessionResultsApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['SessionResult'],
  endpoints: (build) => ({
    getSessionResults: build.query<SessionResultSearchResult, SessionResultSearchQuery>({
      query: (queryParams) => `session-results?${qs.stringify(queryParams)}`,
      providesTags: (result) =>
        result!.data
          ? [
            ...result!.data.map(({ id }) => ({ type: 'SessionResult' as const, id })),
            { type: 'SessionResult', id: 'LIST' },
          ]
          : [{ type: 'SessionResult', id: 'LIST' }],
    }),
    getSessionResultById: build.query<SessionResult, string>({
      query: (id) => `session-results/${id}`,
      providesTags: (result, error, id) => [{ type: 'SessionResult', id }],
    }),
    createSessionResult: build.mutation<SessionResult, SessionResultInput>({
      query: (body) => {
        return {
          url: 'session-results',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'SessionResult', id: 'LIST' }],
    }),
    updateSessionResult: build.mutation<SessionResult, SessionResultInput>({
      query: (body) => ({
        url: `session-results/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'SessionResult', id }],
    }),
    deleteSessionResult: build.mutation<void, string>({
      query: (id) => ({
        url: `session-results/${id}`,
        method: 'DELETE',
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: (result, error, id) => [{ type: 'SessionResult', id }],
    }),
    getSessionStatsBySessionId: build.query<SessionResult[], string>({
      query: (sessionId) => `session-stats/${sessionId}`,
      providesTags: () => [{ type: 'SessionResult', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetSessionResultsQuery,
  useGetSessionResultByIdQuery,
  useCreateSessionResultMutation,
  useUpdateSessionResultMutation,
  useDeleteSessionResultMutation,
  useGetSessionStatsBySessionIdQuery,
} = sessionResultsApi;
