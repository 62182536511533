export default function timeToMs(time: string) {
  if (time === '') return null;

  const [hours, minutes, seconds, milliseconds] = time.split(/:|\./);
  const hoursMs = Number(hours) * 60 * 60 * 1000;
  const minutesMs = Number(minutes) * 60 * 1000;
  const secondsMs = Number(seconds) * 1000;
  const millisecondsMs = Number(milliseconds);
  return hoursMs + minutesMs + secondsMs + millisecondsMs;
}
