import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetSeriesQuery } from 'reducers/series';
import { useGetTeamByIdQuery, useUpdateTeamMutation } from 'reducers/teams';
import TeamFormFields from 'features/Team/TeamFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
  formatPayloadFromFormData,
} from 'features/Team/teamFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';
import { useGetOrganizationsQuery } from 'reducers/organizations';

export default function TeamEdit() {
  const navigate = useNavigate();
  const { teamId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getOrganizationsLoading,
    error: getOrganizationsError,
    data: organizationsData,
  } = useGetOrganizationsQuery({});

  const {
    isLoading: getSeriesLoading,
    error: getSeriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const {
    isLoading: getTeamLoading,
    error: getTeamError,
    data: teamData,
  } = useGetTeamByIdQuery(teamId!);

  const [
    updateTeam,
    {
      isLoading: updateTeamLoading,
      isSuccess: updateTeamSuccess,
      error: updateTeamError,
    },
  ] = useUpdateTeamMutation();

  useInitializeFormFieldValues(teamData, form);

  // Handle successful form submission
  React.useEffect(() => {
    if (updateTeamSuccess) navigate(-1);
  }, [updateTeamSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    payload.id = teamId;

    await updateTeam(payload);
  };

  if (
    getSeriesLoading ||
    getTeamLoading ||
    updateTeamLoading ||
    getOrganizationsLoading
  ) return <LoadingIndicator />;

  if (
    getSeriesError ||
    getTeamError ||
    updateTeamError ||
    getOrganizationsError
  ) throw (
    getSeriesError ||
    getTeamError ||
    updateTeamError ||
    getOrganizationsError
  );

  return (
    <>
      <PageHeader title="Edit Team" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <TeamFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
            organizations={organizationsData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={updateTeamLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
