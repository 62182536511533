import { useEffect } from 'react';

import { Track, TrackInput } from 'types';
import numberToString from 'helpers/numberToString';
import stringToNumber from 'helpers/stringToNumber';

export const fieldsConfig = {
  long_name: {
    name: 'long_name',
    label: 'Long Name',
    validation: {
      required: true,
    },
  },
  short_name: {
    name: 'short_name',
    label: 'Short Name',
    validation: {
      required: true,
    },
  },
  mini_name: {
    name: 'mini_name',
    label: 'Mini Name',
    validation: {
      required: true,
    },
  },
  timezone_id: {
    name: 'timezone_id',
    label: 'Timezone',
  },
  city: {
    name: 'city',
    label: 'City',
  },
  state: {
    name: 'state',
    label: 'State',
  },
  elevation: {
    name: 'elevation',
    label: 'Elevation',
  },
  latitude: {
    name: 'latitude',
    label: 'Latitude',
  },
  longitude: {
    name: 'longitude',
    label: 'Longitude',
  },
};

export interface FormData {
  long_name: string;
  short_name: string;
  mini_name: string;
  timezone_id: string;
  city: string;
  state: string;
  elevation: string;
  latitude: string;
  longitude: string;
};

export const defaultValues = {
  long_name: '',
  short_name: '',
  mini_name: '',
  timezone_id: '',
  city: '',
  state: '',
  elevation: '',
  latitude: '',
  longitude: '',
};

export const useInitializeFormFieldValues = (track: Track | undefined, form: any) => {
  useEffect(() => {
    if (!track) return;

    form.setValue('long_name', track.long_name);
    form.setValue('short_name', track.short_name);
    form.setValue('mini_name', track.mini_name);
    form.setValue('timezone_id', track.timezone_id ?? '');
    form.setValue('city', track.city ?? '');
    form.setValue('state', track.state ?? '');
    form.setValue('elevation', numberToString(track.elevation));
    form.setValue('latitude', numberToString(track.latitude));
    form.setValue('longitude', numberToString(track.longitude));
  }, [track, form]);
};

export const formatPayloadFromFormData = (formData: FormData): TrackInput => {
  return {
    long_name: formData.long_name,
    short_name: formData.short_name,
    mini_name: formData.mini_name,
    timezone_id: formData.timezone_id || null,
    city: formData.city || null,
    state: formData.state || null,
    elevation: stringToNumber(formData.elevation),
    latitude: stringToNumber(formData.latitude),
    longitude: stringToNumber(formData.longitude),
  };
};
