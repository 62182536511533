import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

import type {
  Organization,
  OrganizationInput,
  OrganizationMergeInput,
  OrganizationSearchQuery,
  OrganizationSearchResult,
} from 'types';
import { BASE_URL } from 'config';

export const organizationsApi = createApi({
  reducerPath: 'organizationsApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Organization'],
  endpoints: (build) => ({
    getOrganizations: build.query<OrganizationSearchResult, OrganizationSearchQuery>({
      query: (queryParams) => `organizations?${qs.stringify(queryParams)}`,
      providesTags: (result) =>
        result!.data
          ? [
            ...result!.data.map(({ id }) => ({ type: 'Organization' as const, id })),
            { type: 'Organization', id: 'LIST' },
          ]
          : [{ type: 'Organization', id: 'LIST' }],
    }),
    getOrganizationById: build.query<Organization, string>({
      query: (id) => `organizations/${id}`,
      providesTags: (result, error, id) => [{ type: 'Organization', id }],
    }),
    createOrganization: build.mutation<Organization, OrganizationInput>({
      query: (body) => {
        return {
          url: 'organizations',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'Organization', id: 'LIST' }],
    }),
    updateOrganization: build.mutation<Organization, OrganizationInput>({
      query: (body) => ({
        url: `organizations/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Organization', id }],
    }),
    deleteOrganization: build.mutation<void, string>({
      query: (id) => ({
        url: `organizations/${id}`,
        method: 'DELETE',
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Organization', id }],
    }),
    mergeOrganization: build.mutation<void, OrganizationMergeInput>({
      query: (body) => ({
        url: 'merge/organization',
        method: 'POST',
        body,
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: [{ type: 'Organization', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useGetOrganizationByIdQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  useMergeOrganizationMutation,
} = organizationsApi;
