import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';

import { useGetTracksQuery } from 'reducers/tracks';
import PageHeader from 'components/PageHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import BootstrapTable from 'components/BootstrapTable';

export default function TrackList() {
  const navigate = useNavigate();

  const {
    isLoading: tracksLoading,
    error: tracksError,
    data: tracksData,
  } = useGetTracksQuery({});

  if (tracksLoading) return <LoadingIndicator />;
  if (tracksError) throw tracksError;

  const columns = [
    {
      dataField: 'long_name',
      text: 'Name',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'short_name',
      text: 'Short Name',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'city',
      text: 'City',
      sort: true,
      filter: textFilter(),
      headerStyle: {
        width: 250,
      },
    },
    {
      dataField: 'state',
      text: 'State',
      sort: true,
      filter: textFilter(),
      headerStyle: {
        width: 175,
      },
    },
    {
      dataField: 'timezone_id',
      text: 'Timezone',
      sort: true,
      filter: selectFilter({
        options: [
          { value: 'America/Los_Angeles', label: 'America/Los_Angeles' },
          { value: 'America/Phoenix', label: 'America/Phoenix' },
          { value: 'America/Chicago', label: 'America/Chicago' },
          { value: 'America/New_York', label: 'America/New_York' },
        ],
      }),
      headerStyle: {
        width: 200,
      },
    },
  ];

  const rows: any = _.chain(tracksData!.data)
    .map(track => ({
      _rowLink: `/tracks/${track.id}`,
      id: track.id,
      long_name: track.long_name,
      short_name: track.short_name,
      mini_name: track.mini_name,
      city: track.city,
      state: track.state,
      timezone_id: track.timezone_id,
    }))
    .orderBy([
      (item) => item.short_name,
    ])
    .value();

  const defaultSorted = [{ dataField: 'short_name', order: 'asc' }];

  return (
    <>
      <PageHeader title="Tracks">
        <Button onClick={() => navigate('/tracks/new')}>Add track</Button>
      </PageHeader>

      <BootstrapTable cols={columns} rows={rows} defaultSorted={defaultSorted} />
    </>
  );
}
