import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetSeriesQuery } from 'reducers/series';
import { useCreateSeriesAliasMutation } from 'reducers/series-aliases';
import SeriesAliasFormFields from 'features/SeriesAlias/SeriesAliasFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  formatPayloadFromFormData,
} from 'features/SeriesAlias/seriesAliasFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function SeriesAliasNew() {
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });
  const { seriesId } = useParams();

  const {
    isLoading: seriesLoading,
    error: seriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const [
    createSeriesAlias,
    {
      isLoading: createSeriesAliasLoading,
      isSuccess: createSeriesAliasSuccess,
      error: createSeriesAliasError,
    },
  ] = useCreateSeriesAliasMutation();

  React.useEffect(() => {
    if (seriesId) form.setValue('series_id', seriesId);
  }, [form, seriesId]);

  React.useEffect(() => {
    if (createSeriesAliasSuccess) navigate(-1);
  }, [createSeriesAliasSuccess, navigate, seriesId]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    await createSeriesAlias(payload);
  };

  if (
    seriesLoading ||
    createSeriesAliasLoading
  ) return <LoadingIndicator />;

  if (
    seriesError ||
    createSeriesAliasError
  ) throw (
    seriesError ||
    createSeriesAliasError
  );

  return (
    <>
      <PageHeader title="Add Alias" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <SeriesAliasFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={createSeriesAliasLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
