import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useGetTrackConfigurationByIdQuery, useUpdateTrackConfigurationMutation } from 'reducers/track-configurations';
import { useGetTracksQuery } from 'reducers/tracks';
import TrackConfigurationFormFields from 'features/TrackConfiguration/TrackConfigurationFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
  formatPayloadFromFormData,
} from 'features/TrackConfiguration/trackConfigurationFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function TrackConfigurationEdit() {
  const navigate = useNavigate();
  const { trackConfigurationId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getTracksLoading,
    error: getTracksError,
    data: tracksData,
  } = useGetTracksQuery({});

  const {
    isLoading: getTrackConfigurationLoading,
    error: getTrackConfigurationError,
    data: trackConfigurationData,
  } = useGetTrackConfigurationByIdQuery(trackConfigurationId!);

  const [
    updateTrackConfiguration,
    {
      isLoading: updateTrackConfigurationLoading,
      isSuccess: updateTrackConfigurationSuccess,
      error: updateTrackConfigurationError,
    },
  ] = useUpdateTrackConfigurationMutation();

  useInitializeFormFieldValues(trackConfigurationData, form);

  // Handle successful form submission
  React.useEffect(() => {
    if (updateTrackConfigurationSuccess) navigate(-1);
  }, [updateTrackConfigurationSuccess, navigate]);

  const onSubmit = async (formData: any) => {
    const payload = formatPayloadFromFormData(formData);
    payload.id = trackConfigurationId;

    await updateTrackConfiguration(payload);
  };

  if (
    getTracksLoading ||
    getTrackConfigurationLoading ||
    updateTrackConfigurationLoading
  ) return <LoadingIndicator />;

  if (
    getTracksError ||
    getTrackConfigurationError ||
    updateTrackConfigurationError
  ) throw (
    getTracksError ||
    getTrackConfigurationError ||
    updateTrackConfigurationError
  );

  return (
    <>
      <PageHeader title="Edit Track Configuration" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <TrackConfigurationFormFields
            fieldsConfig={fieldsConfig}
            tracks={tracksData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={updateTrackConfigurationLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
