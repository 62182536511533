import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';

import { useGetSeriesQuery } from 'reducers/series';
import PageHeader from 'components/PageHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import BootstrapTable from 'components/BootstrapTable';

export default function SeriesList() {
  const navigate = useNavigate();

  const {
    isLoading: seriesLoading,
    error: seriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  if (seriesLoading) return <LoadingIndicator />;
  if (seriesError) throw seriesError;

  const columns = [
    {
      dataField: 'letter',
      text: 'Letter',
      sort: true,
      filter: selectFilter({
        options: _.map(seriesData!.data, series => ({
          value: series.letter,
          label: series.letter,
        })),
      }),
    },
    {
      dataField: 'abbreviation',
      text: 'Abbreviation',
      sort: true,
      filter: textFilter(),
    },
  ];

  const rows: any = _.chain(seriesData!.data)
    .map(series => ({
      _rowLink: `/series/${series.id}`,
      id: series.id,
      letter: series.letter,
      abbreviation: series.abbreviation,
    }))
    .orderBy([
      (item) => item.letter,
    ])
    .value();

  const defaultSorted = [{ dataField: 'letter', order: 'asc' }];

  return (
    <>
      <PageHeader title="Series">
        <Button onClick={() => navigate('/series/new')}>Add series</Button>
      </PageHeader>

      <BootstrapTable cols={columns} rows={rows} defaultSorted={defaultSorted} />
    </>
  );
}
