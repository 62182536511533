import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Badge,
  Form,
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetEventByIdQuery, useUpdateEventMutation } from 'reducers/events';
import { useGetSeriesQuery } from 'reducers/series';
import { useGetTrackConfigurationsQuery } from 'reducers/track-configurations';
import { useGetTracksQuery } from 'reducers/tracks';
import EventFormFields from 'features/Event/EventFormFields';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  useInitializeFormFieldValues,
  formatPayloadFromFormData,
} from 'features/Event/eventFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';

export default function EventEdit() {
  const navigate = useNavigate();
  const { eventId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getEventLoading,
    error: getEventError,
    data: event,
  } = useGetEventByIdQuery(eventId!);

  const {
    isLoading: getSeriesLoading,
    error: getSeriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const {
    isLoading: getTracksLoading,
    error: getTracksError,
    data: tracksData,
  } = useGetTracksQuery({});

  const {
    isLoading: getTrackConfigurationsLoading,
    error: getTrackConfigurationsError,
    data: trackConfigurationsData,
  } = useGetTrackConfigurationsQuery({});

  const [
    updateEvent,
    {
      isLoading: updateEventLoading,
      isSuccess: updateEventSuccess,
      error: updateEventError,
    },
  ] = useUpdateEventMutation();

  useInitializeFormFieldValues(event, form);

  // Handle successful form submission
  React.useEffect(() => {
    if (updateEventSuccess) navigate(-1);
  }, [updateEventSuccess, navigate]);

  const onSubmit = async (formData: FormData) => {
    const payload = formatPayloadFromFormData(formData, event!.track_configuration.track.timezone_id);
    await updateEvent({
      ...event!,
      ...payload,
    });
  };

  if (
    getEventLoading ||
    getSeriesLoading ||
    updateEventLoading ||
    getTrackConfigurationsLoading ||
    getTracksLoading
  ) return <LoadingIndicator />;

  if (
    getEventError ||
    getSeriesError ||
    updateEventError ||
    getTrackConfigurationsError ||
    getTracksError
  ) throw (
    getEventError ||
    getSeriesError ||
    updateEventError ||
    getTrackConfigurationsError ||
    getTracksError
  );

  return (
    <>
      <PageHeader title={event!.name}>
        {event!.is_test && <Badge bg="info">Test Event</Badge>}
      </PageHeader>

      <FormProvider {...form}>
        <Form>
          <EventFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
            tracks={tracksData!.data}
            trackConfigurations={trackConfigurationsData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={updateEventLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
}
