import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';

import EventDataSourceFormFields from '../EventDataSourceFormFields';
import {
  FormData,
  defaultValues,
  fieldsConfig,
  formatPayloadFromFormData,
} from '../eventDataSourceFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import { useGetDataSourcesQuery } from 'reducers/data-sources';
import { useGetEventByIdQuery, useUpdateEventMutation } from 'reducers/events';
import FormButtons from 'components/FormButtons';
import { EventInput } from 'types';

export default function EventDataSourceNew() {
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });

  const { eventId } = useParams();

  const {
    isLoading: getEventLoading,
    error: getEventError,
    data: event,
  } = useGetEventByIdQuery(eventId!);

  const {
    isLoading: getDataSourcesLoading,
    error: getDataSourcesError,
    data: dataSources,
  } = useGetDataSourcesQuery();

  const [
    updateEvent,
    {
      isLoading: updateEventLoading,
      error: updateEventError,
      isSuccess: updateEventSuccess,
    },
  ] = useUpdateEventMutation();

  React.useEffect(() => {
    if (updateEventSuccess) navigate(-1);
  }, [updateEventSuccess, navigate]);

  const onSubmit = async (formData: FormData) => {
    const payload = formatPayloadFromFormData(formData);
    const eventInput = {
      ...event,
      data_sources: [
        ...event!.data_sources,
        payload,
      ],
    }
    await updateEvent(eventInput as EventInput);
  };

  const filteredDataSources = React.useMemo(() => {
    if (!dataSources) return [];
    if (!event) return dataSources.data;

    return dataSources.data.filter(
      (dataSource) =>
        !event.data_sources!.some(
          (eventDataSource) => eventDataSource.id === dataSource.id
        )
    );
  }, [event, dataSources]);

  if (
    getEventLoading ||
    getDataSourcesLoading ||
    updateEventLoading
  ) return <LoadingIndicator />;
  if (
    getEventError ||
    getDataSourcesError ||
    updateEventError
  ) throw (getEventError || getDataSourcesError || updateEventError);

  return (
    <>
      <PageHeader title="Add Event Data Source" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <EventDataSourceFormFields
            fieldsConfig={fieldsConfig}
            dataSources={filteredDataSources}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={updateEventLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
}